import React from "react";
import AuthLayout from "../../layout/AuthLayout";
import { Image, useColorModeValue } from "@chakra-ui/react";
import arrow1 from "../../assets/auth/backArrow.svg";
import arrow2 from "../../assets/auth/backArrowDark.svg";
import { useNavigate } from "react-router-dom";
import AuthCardHeader from "../../components/Auth/AuthCardHeader";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

const PasswordChanged = () => {
  const navigate = useNavigate();
  const arrowIcon = useColorModeValue(arrow1, arrow2);

  return (
    <AuthLayout noAuthNav={true} bg={useColorModeValue("#FDFDFD", "#171918")}>
      <Image src={arrowIcon} alt="arrow" cursor={"pointer"} />
      <AuthCardHeader
        fontSize="43px"
        title="Your password
has been successfully changed"
      />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          navigate("/login");
        }}
      >
        <PrimaryButton text={"Sign in"} type="submit" />
      </form>
    </AuthLayout>
  );
};

export default PasswordChanged;
