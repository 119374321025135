import React, { useState } from "react";
import AdminLayout from "../../layout";
import { Box, Flex, Image, Stack } from "@chakra-ui/react";
import CustomText from "../../components/Text/CustomText";
import { SummaryBox } from "../../components/Cards/SummaryBox";
import { useLocation, useNavigate } from "react-router-dom";
import { USER_REFERRAL_ACTIVITY } from "../../utils/constants";
import UserImg from "../../assets/user-pic.png";
import LeftArrow from "../../assets/left-arrow.svg";
import RightArrow from "../../assets/right-arrow.svg";
import { BackButton } from "../../components/Buttons/BackButton";
import Pagination from "../../components/Pagination";
import UserDetailHeader from "../../components/Cards/UserDetailHeader";

const UserKyc = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state;

  const [pageSize, setPageSize] = useState(9); // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page

  const totalPages = Math.ceil(USER_REFERRAL_ACTIVITY.length / pageSize);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentTransactions = USER_REFERRAL_ACTIVITY.slice(
    startIndex,
    endIndex
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle Previous Page
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <AdminLayout>
      <Stack align={"flex-start"} maxW={"1049px"} gap={"23px"}>
        <CustomText text={"User KYC"} size="16px" w="600" />
        <Box
          p={"34px 0px 46px 36px"}
          mt={"23px"}
          borderRadius={"5px"}
          bg={"light.bg"}
          w={"100%"}
        >
          <UserDetailHeader
            date={userData?.date}
            email={userData?.email}
            id={"pay-60001987"}
          />
          <Flex gap={"73px"} align={"flex-start"} mt={"24px"}>
            <Stack align={"flex-start"} gap={"22px"}>
              <Image src={UserImg} alt="user-pic" w={"240px"} h={"154px"} />
              <SummaryBox
                title="Phone Number"
                desc={`0803 000 8001`}
                icon={true}
              />
              <BackButton
                title="Exit to main user directory"
                onClick={() => navigate("/users")}
              />
            </Stack>
            <Stack align={"flex-start"} gap={"22px"}>
              <SummaryBox title="First name" desc={`DAMXTON`} icon={true} />
              <SummaryBox title="Last name" desc={`DAMXTON`} icon={true} />
              <SummaryBox title="NIN number" desc={`91731297555`} icon={true} />

              <SummaryBox title="BVN number" desc={`2213456780`} icon={true} />
              <SummaryBox
                title="NIN and BVN Validation Status"
                desc={`NIN: Success; BVN: Click to Validate`}
                icon={true}
              />
              <BackButton title="Exit to user summary page" />
            </Stack>
            <Stack align={"flex-start"} gap={"22px"}>
              <SummaryBox
                title="House address"
                desc={`No 27 Highstar Street
Sapele road`}
                icon={true}
              />
              <SummaryBox title="Gender" desc={`Male`} icon={true} />
              <SummaryBox
                title="Date of Birth"
                desc={`1999-08-17`}
                icon={true}
              />
              <SummaryBox
                title="Account Number"
                desc={`91731297555`}
                icon={true}
              />
              <SummaryBox
                title="Current User level"
                desc={`Level 1`}
                icon={true}
              />

              <BackButton
                title="Exit to KYC directory"
                onClick={() => navigate("/user-summary")}
                nextP={true}
              />
            </Stack>
          </Flex>
        </Box>
        <Stack gap={"10px"} align={"flex-start"} w={"100%"}>
          <Box
            w={"100%"}
            pl={"32px"}
            py={"12px"}
            borderRadius={"5px"}
            bg={"light.bg"}
            boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.02)"}
          >
            <Flex
              align={"center"}
              gap={"120px"}
              h={"44px"}
              //   justify={"center"}
              alignItems={"center"}
              my={"5px"}
            >
              <Box w={"142px"}>
                <CustomText text={"Validation History"} size="13px" w="500" />
              </Box>
              <Box w={"274px"}>
                <CustomText text={"Remarks"} size="13px" w="500" />
              </Box>
              <Box w={"254px"}>
                <CustomText text={"Approved/rejected by"} size="13px" w="500" />
              </Box>
            </Flex>
            {currentTransactions?.map((activity) => (
              <Flex
                align={"center"}
                gap={"120px"}
                h={"44px"}
                //   justify={"center"}
                alignItems={"center"}
                my={"5px"}
              >
                <Box w={"142px"}>
                  <CustomText text={activity?.date} size="13px" lh="18px" />
                </Box>
                <Box w={"274px"}>
                  <CustomText
                    text={"Phone Number does not match"}
                    size="13px"
                    lh="18px"
                  />
                </Box>
                <Box w={"254px"}>
                  <CustomText text={"John Mark"} size="13px" lh="18px" />
                </Box>
              </Flex>
            ))}
          </Box>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageSizeChange={(newSize: any) => {
              setPageSize(newSize);
              setCurrentPage(1); // Reset to page 1 when page size changes
            }}
            onPreviousPage={handlePreviousPage}
            onNextPage={handleNextPage}
            leftArrowIcon={LeftArrow}
            rightArrowIcon={RightArrow}
          />
        </Stack>
      </Stack>
    </AdminLayout>
  );
};

export default UserKyc;
