import React from "react";
import { Flex, Select, Center, Image } from "@chakra-ui/react";
import PrimaryText from "./Text/PrimaryText";

const Pagination = ({
  currentPage,
  totalPages,
  pageSize,
  pageSizes = [9, 10, 50, 500, 1000, 5000],
  onPageSizeChange,
  onPreviousPage,
  onNextPage,
  leftArrowIcon,
  rightArrowIcon,
}: any) => {
  return (
    <Flex align="center" justify="space-between" w="100%" maxW="1009px">
      <Flex align="center" gap="10px">
        <PrimaryText text="Showing" />
        <Select
          variant="primary"
          w="70px"
          h="44px"
          borderRadius="5px"
          bg="light.bg"
          boxShadow="0px 4px 40px 0px rgba(0,0,0,0.2)"
          onChange={(e) => {
            onPageSizeChange(Number(e.target.value));
          }}
          value={pageSize}
          textColor="light.text.primary"
          fontSize="14px"
        >
          {pageSizes.map((size: any) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex align="center" gap="20px">
        {/* Previous Button */}
        <Center
          w="32px"
          h="44px"
          borderRadius="5px"
          bg="light.bg"
          boxShadow="0px 4px 40px 0px rgba(0,0,0,0.02)"
          cursor={currentPage === 1 ? "not-allowed" : "pointer"}
          onClick={onPreviousPage}
        >
          <Image
            src={leftArrowIcon}
            alt="previous page"
            opacity={currentPage === 1 ? 0.5 : 1}
          />
        </Center>

        {/* Next Button */}
        <Center
          w="32px"
          h="44px"
          borderRadius="5px"
          bg="light.bg"
          boxShadow="0px 4px 40px 0px rgba(0,0,0,0.02)"
          cursor={currentPage === totalPages ? "not-allowed" : "pointer"}
          onClick={onNextPage}
        >
          <Image
            src={rightArrowIcon}
            alt="next page"
            opacity={currentPage === totalPages ? 0.5 : 1}
          />
        </Center>
      </Flex>
    </Flex>
  );
};

export default Pagination;
