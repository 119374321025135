import React, { useState } from "react";
import AdminLayout from "../../layout";
import TodayText from "../../assets/Today.svg";
import WeekText from "../../assets/Week.svg";
import MonthText from "../../assets/Month.svg";
import TotalText from "../../assets/Total.svg";
import SettingIcon from "../../assets/setting.svg";
import SearchBlack from "../../assets/search-black.svg";
import FilterIcon from "../../assets/filter-icon.svg";
import FilterIcon2 from "../../assets/filter-icon-2.svg";
import UserIcon from "../../assets/user-icon-filled.svg";
import {
  Box,
  Center,
  Flex,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
} from "@chakra-ui/react";
import CustomText from "../../components/Text/CustomText";
import ExportButton from "../../components/Buttons/ExportButton";
import { KYC, USERS } from "../../utils/constants";
import LeftArrow from "../../assets/left-arrow.svg";
import RightArrow from "../../assets/right-arrow.svg";
import PrimaryText from "../../components/Text/PrimaryText";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(9); // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [selectedTab, setSelectedTab] = useState("User Directory");

  const totalPages = Math.ceil(USERS.length / pageSize);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentTransactions = USERS.slice(startIndex, endIndex);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle Previous Page
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <AdminLayout maxW="1129px">
      {selectedTab === "User Directory" && <UserStatBoxes />}
      <CustomText text={selectedTab} size="16px" w="600" />
      <Stack gap={"0px"} maxW={"1047px"}>
        <Flex justify={"space-between"} align={"center"} mt={"24px"}>
          <Flex align={"center"} gap={"0px"}>
            <UsersHeader
              title="User Directory"
              onClick={() => setSelectedTab("User Directory")}
              selectedTab={selectedTab}
            />
            <UsersHeader
              title="KYC"
              onClick={() => setSelectedTab("KYC")}
              selectedTab={selectedTab}
              ml={selectedTab !== "KYC" ? "-66px" : "0px"}
            />
          </Flex>
          <ExportButton />
        </Flex>
        {selectedTab === "User Directory" ? (
          <Stack align={"flex-start"} gap={"6px"}>
            {currentTransactions?.map((user: any) => (
              <UserRow
                user={user}
                onClick={() => navigate("/user-summary", { state: user })}
              />
            ))}
          </Stack>
        ) : (
          <Stack align={"flex-start"} gap={"6px"}>
            {KYC?.map((kyc: any) => (
              <UserRow user={kyc} gap="100px" />
            ))}
          </Stack>
        )}
        {/* footer */}
        <Flex align={"center"} justify={"space-between"} w={"100%"} mt={"6px"}>
          <Flex align={"center"} gap={"10px"}>
            <PrimaryText text="Showing" />
            <Select
              variant={"primary"}
              w="70px"
              h="44px"
              borderRadius={"5px"}
              bg={"light.bg"}
              boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.2)"}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setCurrentPage(1); // Reset to page 1 when page size changes
              }}
              value={pageSize}
              textColor={"light.text.primary"}
              fontSize={"14px"}
            >
              {[9, 10, 50, 500, 1000, 5000].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex align={"center"} gap={"20px"}>
            {/* Previous Button */}
            <Center
              w={"32px"}
              h={"44px"}
              borderRadius={"5px"}
              bg={"light.bg"}
              boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.02)"}
              cursor={currentPage === 1 ? "not-allowed" : "pointer"}
              onClick={handlePreviousPage}
            >
              <Image
                src={LeftArrow}
                alt="previous page"
                opacity={currentPage === 1 ? 0.5 : 1}
              />
            </Center>

            {/* Next Button */}
            <Center
              w={"32px"}
              h={"44px"}
              borderRadius={"5px"}
              bg={"light.bg"}
              boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.02)"}
              cursor={currentPage === totalPages ? "not-allowed" : "pointer"}
              onClick={handleNextPage}
            >
              <Image
                src={RightArrow}
                alt="next page"
                opacity={currentPage === totalPages ? 0.5 : 1}
              />
            </Center>
          </Flex>
        </Flex>
      </Stack>
    </AdminLayout>
  );
};

export default Users;

const UserStatBoxes = () => {
  return (
    <Flex align={"center"} gap={"24px"} mb={"25px"}>
      <Center
        p={"12px 21.252px 12px 21.748px"}
        borderRadius={"10px"}
        border={"0.3px solid"}
        borderColor={"light.remainder"}
        h={"88px"}
      >
        <Flex gap={"15px"} align={"flex-start"}>
          <Flex gap={"8px"} align={"center"}>
            <StatStack textImg={TodayText} text="19" />
            <StatStack textImg={WeekText} text="19" />
            <StatStack textImg={MonthText} text="19" />
          </Flex>
          <Center flexDirection={"column"} gap={"14px"}>
            <Image src={SettingIcon} alt="settings" w={"14px"} h={"15px"} />
            <Image src={UserIcon} alt="user" w={"30px"} />
          </Center>
        </Flex>
      </Center>
      <Center
        p={"12px 21.252px 12px 21.748px"}
        borderRadius={"10px"}
        border={"0.3px solid"}
        borderColor={"light.remainder"}
        gap={"10px"}
        h={"88px"}
      >
        <StatStack textImg={TotalText} text="20,000" justify="flex-start" />
        <Center flexDirection={"column"} gap={"14px"} mb={"12px"}>
          <Image src={SettingIcon} alt="settings" w={"14px"} h={"15px"} />
          <Image src={UserIcon} alt="user" w={"28px"} />
        </Center>
      </Center>
    </Flex>
  );
};

const StatStack = ({ textImg, text, justify }: any) => {
  return (
    <Stack gap={"15px"} justify={"center"} align={justify || "center"}>
      <Image src={textImg} alt="today" />
      <CustomText
        text={text}
        fontFeature={true}
        size="24px"
        w="500"
        lh="36px"
      />
    </Stack>
  );
};

const UsersHeader = ({ title, onClick, selectedTab, ml }: any) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const filterOptions: any = [
    {
      label: "Date Created",
      submenu: [
        "24 hours",
        "This month",
        "Last 12 months",
        "Custom",
        "All time",
      ],
    },
    {
      label: "App Version",
      submenu: ["Version 1", "Version 2", "All Versions"],
    },
    {
      label: "Userlevel",
      submenu: ["level 1", "level 2", "level 3", "All level"],
    },
    {
      label: "Account Status",
      submenu: ["Active", "Deactivated", "Deleted", "All"],
    },
  ];

  const filterOptions2: any = [
    {
      label: "Date",
      submenu: [
        "24 hours",
        "This month",
        "Last 12 months",
        "Custom",
        "All time",
      ],
    },
    {
      label: "Status",
      submenu: ["Approved", "Pending", "Rejected", "All"],
    },
  ];

  return (
    <Flex
      w={{
        base: "100%",
        sm: "300px",
        md: "430px",
      }}
      h={"49px"}
      flexShrink={"0"}
      borderRadius={"20px 20px 0px 0px"}
      bg={selectedTab === title ? "#FFF" : "#E2E2E2"}
      pt={"6px"}
      pl={{ base: "12px", md: "24px" }}
      gap={"11px"}
      align={"center"}
      onClick={onClick}
      cursor={"pointer"}
      zIndex={selectedTab === title ? "1" : "0"}
      ml={ml || 0}
    >
      <Flex align={"center"} gap={"11px"} w={"100%"}>
        <Flex
          w={{ base: "100%", md: "399px" }}
          borderRadius={"10px"}
          bg={selectedTab === title ? "#FFF" : "#E2E2E2"}
          justify={"space-between"}
          align={"center"}
          p={"6px 12px"}
        >
          <Flex align={"center"} gap={{ base: "10px", md: "25px" }}>
            <Image
              src={SearchBlack}
              w={{ base: "10px", md: "20px" }}
              h={{ base: "10px", md: "20px" }}
              cursor="pointer"
              //   onClick={handleSearchClick} // Handle search icon click
            />
            <Input
              placeholder={title}
              //   value={searchQuery}
              //   onChange={handleSearchInputChange}
              //   onBlur={handleInputBlur} // Revert on blur
              border="none"
              bg="transparent"
              variant={"unstyled"}
              w={{ md: "300px" }}
              color={"#000"}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={"14px"}
              fontWeight={"500"}
              lineHeight={"25px"}
              _placeholder={{
                color: "light.remainder",
                fontFamily: "Plus Jakarta Sans",
                fontSize: "15px",
                fontWeight: "500",
                lineHeight: "25px",
              }}
            />
          </Flex>
          <Menu>
            <MenuButton>
              <Image src={FilterIcon} w={"20px"} h={"20px"} cursor="pointer" />
            </MenuButton>
            <MenuList p={"0px"}>
              <MenuItem _hover={{ bg: "none" }} fontWeight={"600"}>
                Search With
              </MenuItem>

              {filterOptions.map((filterItem: any, index: any) => (
                <MenuItem
                  key={index}
                  _hover={{ bg: "#67C475" }}
                  onMouseEnter={() => setHoveredItem(filterItem.label)} // Show dropdown on hover
                  onMouseLeave={() => setHoveredItem(null)} // Hide dropdown when not hovering
                  position="relative"
                >
                  <Flex justify="space-between" align="center" w="100%">
                    {filterItem.label}
                  </Flex>

                  {/* Render submenu on hover */}
                  {hoveredItem === filterItem.label && (
                    <Box
                      position="absolute"
                      left="100%"
                      top="0"
                      bg="white"
                      w="150px"
                      boxShadow="md"
                      zIndex="10"
                    >
                      {filterItem.submenu.map((submenuItem: any, idx: any) => (
                        <MenuItem
                          key={idx}
                          _hover={{ bg: "#67C475" }}
                          bg={"none"}
                        >
                          {submenuItem}
                        </MenuItem>
                      ))}
                    </Box>
                  )}
                </MenuItem>
              ))}

              {/* Adding last filter item without submenu */}
              <MenuItem _hover={{ bg: "#67C475" }}>
                <Flex justify="space-between" align="center" w="100%">
                  User with Zero balance
                </Flex>
              </MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton ml={"10px"}>
              <Image src={FilterIcon2} w={"20px"} h={"20px"} cursor="pointer" />
            </MenuButton>
            <MenuList p={"0px"}>
              <MenuItem _hover={{ bg: "none" }} fontWeight={"600"}>
                Search By
              </MenuItem>

              {filterOptions2.map((filterItem: any, index: any) => (
                <MenuItem
                  key={index}
                  _hover={{ bg: "#67C475" }}
                  onMouseEnter={() => setHoveredItem(filterItem.label)} // Show dropdown on hover
                  onMouseLeave={() => setHoveredItem(null)} // Hide dropdown when not hovering
                  position="relative"
                >
                  <Flex justify="space-between" align="center" w="100%">
                    {filterItem.label}
                  </Flex>

                  {/* Render submenu on hover */}
                  {hoveredItem === filterItem.label && (
                    <Box
                      position="absolute"
                      left="100%"
                      top="0"
                      bg="white"
                      w="150px"
                      boxShadow="md"
                      zIndex="10"
                    >
                      {filterItem.submenu.map((submenuItem: any, idx: any) => (
                        <MenuItem
                          key={idx}
                          _hover={{ bg: "#67C475" }}
                          bg={"none"}
                        >
                          {submenuItem}
                        </MenuItem>
                      ))}
                    </Box>
                  )}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </Flex>
  );
};

const UserRow = ({ user, gap, onClick }: any) => {
  return (
    <Center
      gap={gap || "140px"}
      h={"44px"}
      w="100%"
      borderRadius={"5px"}
      bg={"light.bg"}
      _hover={{ bg: "light.primary" }}
      cursor={"pointer"}
      onClick={onClick}
      boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.2)"}
    >
      <CustomText text={user?.date} size="13px" />
      <CustomText text={user?.name} size="13px" />
      <CustomText text={user?.email} size="13px" />
      <CustomText text={user?.level} size="13px" />
      {user?.KycStatus && (
        <CustomText
          text={user?.KycStatus}
          color={
            user?.KycStatus === "Rejected"
              ? "#FF0017"
              : user?.KycStatus === "Approved"
              ? "#67C475"
              : "dark.bg"
          }
          size="13px"
        />
      )}
      <Image src={FilterIcon} alt="filter" />
    </Center>
  );
};
