import {
  Box,
  Center,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useState } from "react";
import SettingIcon from "../../assets/setting.svg";
import CustomText from "../Text/CustomText";

const ExportButton = () => {
  const [hoveredFilterItem, setHoveredFilterItem] = useState<string | null>(
    null
  ); // Track hovered filter item
  const [filterItem] = useState("");
  return (
    <Center
      h={"49px"}
      borderRadius={"20px 20px 0px 0px"}
      bg={"light.bg"}
      p={{ base: "5.5px 20px 4px 12px", md: "11px 40px 8px 24px" }}
      gap={{ base: "8px", md: "15px" }}
    >
      <CustomText
        text="Export"
        size={{ base: "12px", md: "15px" }}
        w="500"
        color="rgba(0,0,0,0.50)"
      />
      <Menu>
        <MenuButton>
          <Image
            src={SettingIcon}
            alt="settings"
            w={{ base: "16px", md: "32px" }}
          />
        </MenuButton>
        <MenuList p={"0px"}>
          <MenuItem _hover={{ bg: "none" }} fontWeight={"600"}>
            Export Date Range
          </MenuItem>
          <MenuItem
            _hover={{ bg: "#67C475" }}
            onMouseEnter={() => setHoveredFilterItem(filterItem)} // Show dropdown on hover
            onMouseLeave={() => setHoveredFilterItem(null)} // Hide dropdown when not hovering
          >
            <Flex justify="space-between" align="center" w="100%">
              24 hours
            </Flex>
            <Box
              position="absolute"
              // left="100%"
              right={"100%"}
              top="9"
              bg="white"
              w="150px"
              boxShadow="md"
              visibility={
                hoveredFilterItem === filterItem ? "visible" : "hidden"
              } // Show only if the current filter item is hovered
            >
              <MenuItem _hover={{ bg: "none" }}>File type</MenuItem>
              <MenuItem _hover={{ bg: "#67C475" }}>CSV</MenuItem>
            </Box>
          </MenuItem>
          <MenuItem
            _hover={{ bg: "#67C475" }}
            onMouseEnter={() => setHoveredFilterItem(filterItem)} // Show dropdown on hover
            onMouseLeave={() => setHoveredFilterItem(null)} // Hide dropdown when not hovering
          >
            <Flex justify="space-between" align="center" w="100%">
              This month
            </Flex>
            <Box
              position="absolute"
              // left="100%"
              right={"100%"}
              top="9"
              bg="white"
              w="150px"
              boxShadow="md"
              visibility={
                hoveredFilterItem === filterItem ? "visible" : "hidden"
              } // Show only if the current filter item is hovered
            >
              <MenuItem _hover={{ bg: "none" }}>File type</MenuItem>
              <MenuItem _hover={{ bg: "#67C475" }}>CSV</MenuItem>
            </Box>
          </MenuItem>
          <MenuItem
            _hover={{ bg: "#67C475" }}
            onMouseEnter={() => setHoveredFilterItem(filterItem)} // Show dropdown on hover
            onMouseLeave={() => setHoveredFilterItem(null)} // Hide dropdown when not hovering
          >
            <Flex justify="space-between" align="center" w="100%">
              Last 12 months
            </Flex>
            <Box
              position="absolute"
              // left="100%"
              right={"100%"}
              top="9"
              bg="white"
              w="150px"
              boxShadow="md"
              visibility={
                hoveredFilterItem === filterItem ? "visible" : "hidden"
              } // Show only if the current filter item is hovered
            >
              <MenuItem _hover={{ bg: "none" }}>File type</MenuItem>
              <MenuItem _hover={{ bg: "#67C475" }}>CSV</MenuItem>
            </Box>
          </MenuItem>
          <MenuItem
            _hover={{ bg: "#67C475" }}
            onMouseEnter={() => setHoveredFilterItem(filterItem)} // Show dropdown on hover
            onMouseLeave={() => setHoveredFilterItem(null)} // Hide dropdown when not hovering
          >
            <Flex justify="space-between" align="center" w="100%">
              Custom
            </Flex>
            <Box
              position="absolute"
              // left="100%"
              right={"100%"}
              top="9"
              bg="white"
              w="150px"
              boxShadow="md"
              visibility={
                hoveredFilterItem === filterItem ? "visible" : "hidden"
              } // Show only if the current filter item is hovered
            >
              <MenuItem _hover={{ bg: "none" }}>File type</MenuItem>
              <MenuItem _hover={{ bg: "#67C475" }}>CSV</MenuItem>
            </Box>
          </MenuItem>
          <MenuItem
            _hover={{ bg: "#67C475" }}
            onMouseEnter={() => setHoveredFilterItem(filterItem)} // Show dropdown on hover
            onMouseLeave={() => setHoveredFilterItem(null)} // Hide dropdown when not hovering
          >
            <Flex justify="space-between" align="center" w="100%">
              All time
            </Flex>
            <Box
              position="absolute"
              // left="100%"
              right={"100%"}
              top="9"
              bg="white"
              w="150px"
              boxShadow="md"
              visibility={
                hoveredFilterItem === filterItem ? "visible" : "hidden"
              } // Show only if the current filter item is hovered
            >
              <MenuItem _hover={{ bg: "none" }}>File type</MenuItem>
              <MenuItem _hover={{ bg: "#67C475" }}>CSV</MenuItem>
            </Box>
          </MenuItem>
        </MenuList>
      </Menu>
    </Center>
  );
};

export default ExportButton;
