import { Button, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import customTheme from "../../theme";

const CustomButton = ({
  text,
  onClick,
  type,
  opacity,
  icon,
  bgLight,
  disabled,
  isLoading,
  height,
  p,
  w,
  bgColor,
  textColor,
  borderC,
  br,
  textColorHover,
  h,
  textA,
  hoverBg,
}: any) => {
  const bg = useColorModeValue(
    bgLight || customTheme.colors.dark.accent.primary,
    customTheme.colors.light.accent.primary
  );
  const color = useColorModeValue(
    customTheme.colors.light.accent.primary,
    customTheme.colors.dark.accent.primary
  );

  return (
    <Button
      variant={"unstyled"}
      height={height}
      _hover={{
        bg: hoverBg ? hoverBg : bgColor ? bgColor : bg,
        textColor: textColorHover,
      }}
      h={h || "66px"}
      p={p || "24px 10px"}
      w={w || "100%"}
      bg={bgColor || bg}
      color={textColor || color}
      borderRadius={br || "12px"}
      border="1px solid"
      borderColor={borderC || "light.border.secondary"}
      onClick={onClick}
      type={type}
      gap="10px"
      alignItems={"center"}
      isDisabled={disabled}
      isLoading={isLoading}
      fontSize={"13px"}
      fontWeight={"500"}
      lineHeight="14px"
      textAlign={textA || "center"}
      fontFamily={"Plus Jakarta Sans"}
    >
      <span style={{ opacity: opacity || 1 }}>{text}</span> {icon}
    </Button>
  );
};

export default CustomButton;
