import React, { useEffect, useState } from "react";
import {
  Flex,
  Image,
  PinInput,
  PinInputField,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import arrow1 from "../../assets/auth/backArrow.svg";
import arrow2 from "../../assets/auth/backArrowDark.svg";
import AuthLayout from "../../layout/AuthLayout";
import AuthCardHeader from "../../components/Auth/AuthCardHeader";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

const VerifyEmail = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [pinInput, setPinInput] = useState("");
  const [isVerified, setIsVerified] = useState(3);
  const [timer, setTimer] = useState(60);
  const fromPage = location?.state?.from;
  const des = `We have sent an email to your email with a verification code`;

  const arrowIcon = useColorModeValue(arrow1, arrow2);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const displayTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      } else {
        clearInterval(countdown);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [timer]);

  return (
    <AuthLayout noAuthNav={true} bg={useColorModeValue("#FDFDFD", "#171918")}>
      <Image
        src={arrowIcon}
        alt="arrow"
        onClick={() => {
          if (fromPage === "forgotPassword") {
            navigate("/auth/forgot-password");
          } else {
            navigate("/auth/signup");
          }
        }}
        cursor={"pointer"}
      />
      <AuthCardHeader
        fontSize="39px"
        desFontSize="27px"
        title="Enter your Verification Code"
        des={des}
        desFamily={"Plus Jakarta Sans"}
        desColor={useColorModeValue("#000", "#FFF")}
      />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          navigate("/reset-password");
        }}
      >
        <Flex justify={"space-between"} gap="10px" fontFamily={"Roboto"}>
          <PinInput
            errorBorderColor="red.300"
            focusBorderColor="#00FFE0"
            placeholder="0"
            value={pinInput}
            isInvalid={isVerified === 0 && pinInput !== ""}
            onChange={(value) => {
              setPinInput(value);
              setIsVerified(1);
            }}
            autoFocus
          >
            <PinInputField
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              height="117px"
              w={"68px"}
              border={"2px solid"}
              borderColor={"#00FFE0"}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"44px"}
            />
            <PinInputField
              height="117px"
              w={"68px"}
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              border={"2px solid"}
              borderColor={"#00FFE0"}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"44px"}
            />
            <PinInputField
              height="117px"
              w={"68px"}
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              border={"2px solid"}
              borderColor={"#00FFE0"}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"44px"}
            />
            <PinInputField
              height="117px"
              w={"68px"}
              border={"2px solid"}
              borderColor={"#00FFE0"}
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"44px"}
            />
            <PinInputField
              height="117px"
              w={"68px"}
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              border={"2px solid"}
              borderColor={"#00FFE0"}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"44px"}
            />
            <PinInputField
              height="117px"
              w={"68px"}
              _placeholder={{ color: "#C7C7C7" }}
              fontWeight={700}
              border={"2px solid"}
              borderColor={"#00FFE0"}
              color={useColorModeValue("#5A5A5A", "#EEEEEE")}
              fontFamily={"Roboto"}
              fontSize={"44px"}
            />
          </PinInput>
        </Flex>
        <Flex justify={"space-between"} mt="20px" align={"center"}>
          {isVerified === 0 && pinInput?.length === 6 && (
            <Text color="red.300">Incorrect verification code.</Text>
          )}
          <Flex
            gap="5px"
            align="center"
            fontSize={"12px"}
            justify={"end"}
            ml="auto"
          >
            <Text color={useColorModeValue("#5A5A5A", "#C7C7C7")}>
              {displayTime === "00:00"
                ? "Didn’t received code?"
                : "Resend within"}
            </Text>
            <Text
              color={useColorModeValue("#00332D", "#00FFE0")}
              fontWeight={700}
              cursor={displayTime === "00:00" ? "pointer" : ""}
            >
              {displayTime === "00:00" ? "Send Again" : displayTime}
            </Text>
          </Flex>
        </Flex>
        <PrimaryButton text={"Submit"} type="submit" />
        <Flex
          justify={"center"}
          align={"center"}
          gap={"2px"}
          fontSize={"25px"}
          fontFamily={"Plus Jakarta Sans"}
          mt={"20px"}
        >
          <Text color={"#000"}>Didn't get code?</Text>
          <Text fontWeight={"700"} color={"#348537"}>
            Resend code
          </Text>
        </Flex>
      </form>
    </AuthLayout>
  );
};

export default VerifyEmail;
