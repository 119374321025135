import React, { useState } from "react";
import AdminLayout from "../../layout";
import { Box, Flex, Image, Input, Stack } from "@chakra-ui/react";
import CustomText from "../../components/Text/CustomText";
import TickIcon from "../../assets/tick-icon.svg";
import SettingIcon from "../../assets/setting.svg";
import ArrowUpOrange from "../../assets/arrow-up-orange.svg";
import ArrowUp from "../../assets/arrow-up.svg";
import CustomButton from "../../components/Buttons/CustomButton";
import { CRYPT_PRICES, EXCHANGE_RATES } from "../../utils/constants";
import DetailCard from "../../components/Cards/DetailCard";

const Rates = () => {
  const [selectedCoin, setSelectedCoin] = useState<any>(null); // Track selected transaction
  const [toastContent, setToastContent] = useState({
    icon: "",
    text: "",
    textColor: "",
  });

  return (
    <AdminLayout
      icon={toastContent.icon}
      text={toastContent.text}
      textColor={toastContent.textColor}
    >
      <Flex align={"flex-start"} gap={"60px"}>
        <Stack w={"60%"} gap={"11px"}>
          {selectedCoin === null ? (
            <Stack
              p={"40px 76px 28px 32px"}
              gap={"26px"}
              borderRadius={"5px"}
              bg={"#FFF"}
              boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.2)"}
            >
              <CustomText text="Global rates" w="500" lh="normal" size="16px" />
              <Flex align={"center"} gap={"90px"}>
                <Flex gap={"22px"} align={"center"}>
                  <Input
                    variant={"unstyled"}
                    w={"170px"}
                    h={"66px"}
                    borderRadius={"5px"}
                    border={"1px solid #D9D9D9"}
                    bg={"light.bg"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    textAlign={"center"}
                    _placeholder={{
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "500",
                      opacity: "0.3",
                    }}
                    placeholder="Update buy Price"
                  />
                  <Input
                    variant={"unstyled"}
                    w={"170px"}
                    h={"66px"}
                    borderRadius={"5px"}
                    border={"1px solid #D9D9D9"}
                    bg={"light.bg"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    textAlign={"center"}
                    _placeholder={{
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "500",
                      opacity: "0.3",
                    }}
                    placeholder="Update sell Price"
                  />
                </Flex>

                <CustomButton
                  text="Update"
                  bgColor="light.bg"
                  borderC="#D9D9D9"
                  opacity="0.3"
                  hoverBg="light.primary"
                  onClick={() =>
                    setToastContent({
                      icon: TickIcon,
                      text: "Global rates updated",
                      textColor: "light.text.secondary",
                    })
                  }
                />
              </Flex>
            </Stack>
          ) : (
            <Stack
              p={"29px 37px 0px 28px"}
              h={"511px"}
              borderRadius={"5px"}
              bg={"light.bg"}
              gap={"40px"}
            >
              <Flex align={"center"} gap={"19px"}>
                <Image src={selectedCoin?.icon} />
                <Flex align={"center"} gap={"0px"}>
                  <CustomText
                    text={`${selectedCoin?.currency}/`}
                    size="20px"
                    w="700"
                  />
                  <CustomText text={`NGN`} size="20px" w="400" />
                </Flex>
              </Flex>
              <Flex align={"flex-start"} gap={"61px"}>
                <Stack align={"flex-start"} gap={"22px"} ml={"16px"}>
                  <DetailCard
                    title="Previous Rates"
                    desc={`₦${selectedCoin?.buy}`}
                    titleSize="14px"
                    descW="500"
                  />
                  <CustomButton
                    text="Back to rates page"
                    bgColor="light.bg"
                    borderC="#D9D9D9"
                    hoverBg="light.primary"
                    br="5px"
                    onClick={() => setSelectedCoin(null)}
                  />
                </Stack>
                <Stack gap={"22px"} align={"flex-start"}>
                  <RatesInput title="Buy Rates" placeholder="Set new rates" />
                  <RatesInput title="Sell Rates" placeholder="Set new rates" />
                  <CustomButton
                    text="Update"
                    br="5px"
                    onClick={() => {
                      setToastContent({
                        icon: TickIcon,
                        text: `${selectedCoin?.currency} rates updated`, // Fix here by using template literal correctly
                        textColor: "light.text.secondary",
                      });
                    }}
                  />
                </Stack>
              </Flex>
            </Stack>
          )}
          {EXCHANGE_RATES?.map((rate: any) => (
            <React.Fragment key={rate?.currency}>
              {selectedCoin !== null ? (
                // Render something specific if selectedCoin matches the rate's currency
                <div></div>
              ) : (
                <RatesRow
                  rate={rate}
                  setSelectedCoin={setSelectedCoin}
                  selectedCoin={selectedCoin}
                />
              )}
            </React.Fragment>
          ))}
        </Stack>
        <Stack w={"30%"} gap={"11px"}>
          <Box ml={"10px"}>
            <CustomText text="Crypto Prices" w="500" />
          </Box>
          {CRYPT_PRICES?.map((price: any) => (
            <CryptoPriceRow price={price} />
          ))}
        </Stack>
      </Flex>
    </AdminLayout>
  );
};

export default Rates;

const RatesRow = ({ rate, setSelectedCoin, selectedCoin }: any) => {
  return (
    <Flex
      align={"center"}
      // gap={"55px"}
      p={"14px 40px 26px 32px"}
      borderRadius={"5px"}
      bg={"light.bg"}
      boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.2)"}
      cursor={"pointer"}
      onClick={() => setSelectedCoin(rate)}
      display={selectedCoin === null ? "flex" : "none"}
    >
      <Image src={rate?.icon} alt="crypto icon" w={"30px"} />
      <Flex align={"center"} gap={"0px"} w={"90px"} ml={"50px"} mr={"55px"}>
        <CustomText text={`${rate?.currency}/`} size="16px" w="700" />
        <CustomText text={"NGN"} size="16px" />
      </Flex>
      <Flex align={"center"} gap={"22px"} mr={"35px"}>
        <Input
          variant={"unstyled"}
          w={"170px"}
          h={"66px"}
          borderRadius={"5px"}
          border={"1px solid #D9D9D9"}
          bg={"light.bg"}
          display={"flex"}
          readOnly
          cursor={"unset"}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
          fontSize={"15px"}
          fontFamily={"Plus Jakarta Sans"}
          value={`Buy- ₦${rate?.buy}`}
        />
        <Input
          variant={"unstyled"}
          w={"170px"}
          h={"66px"}
          borderRadius={"5px"}
          border={"1px solid #D9D9D9"}
          bg={"light.bg"}
          display={"flex"}
          readOnly
          cursor={"unset"}
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
          fontSize={"15px"}
          fontFamily={"Plus Jakarta Sans"}
          value={`Buy- ₦${rate?.sell}`}
        />
      </Flex>
      <Image src={SettingIcon} alt="setting" />
    </Flex>
  );
};

const CryptoPriceRow = ({ price }: any) => {
  return (
    <Flex
      align={"center"}
      justify={"space-between"}
      p={"12px 10px 9px 12px"}
      borderRadius={"5px"}
      bg={"light.bg"}
      border={"1px solid"}
      borderColor={"light.bg"}
    >
      <Flex align={"center"} gap={"10px"}>
        <Image src={price.icon} />
        <Stack gap={"8px"}>
          <Flex align={"center"} gap={"0px"} w={"90px"}>
            <CustomText text={`${price?.coin}/`} size="14px" w="700" />
            <CustomText text={"USD"} size="14px" />
          </Flex>
          <Flex gap={"3px"} align={"center"}>
            <CustomText
              text={price?.change}
              color={price?.coin === "TON" ? "#FF6674" : "#000"}
              size="10px"
              w="500"
            />
            <Image
              src={price?.coin === "TON" ? ArrowUpOrange : ArrowUp}
              alt="uparrow"
            />
          </Flex>
        </Stack>
      </Flex>
      <CustomText text="$10,810.03" size="16px" w="500" />
    </Flex>
  );
};

const RatesInput = ({ title, placeholder }: any) => {
  return (
    <Stack w={"100%"} width={"240px"}>
      <Flex
        p={"5px 6px"}
        maxW={{ base: "141px", md: "191px" }}
        borderRadius={"5px"}
        bg={"light.bgGray"}
        mb={"-30px"}
        ml={"-10px"}
        position={"relative"}
      >
        <CustomText
          text={title}
          size={{ base: "11px", md: "13px" }}
          w="600"
          lh={{ base: "14px", md: "17px" }}
        />
      </Flex>
      <Flex
        p={"32px 0px 11px 9px"}
        maxW={{ base: "180px", md: "240px" }}
        borderRadius={"5px"}
        border={"1px solid"}
        borderColor={"light.border.secondary"}
        bg={"light.bg"}
      >
        <Input
          variant={"unstyled"}
          placeholder={placeholder}
          _placeholder={{
            fontSize: "13px",
            fontStyle: "italic",
            lineHeight: "17px",
            fontWeight: "400",
            opacity: "0.5",
          }}
        />
      </Flex>
    </Stack>
  );
};
