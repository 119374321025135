import React from "react";
import AdminLayout from "../../layout";
import { Box, Flex, Stack } from "@chakra-ui/react";
import CustomText from "../../components/Text/CustomText";
import DetailCard from "../../components/Cards/DetailCard";
import { BackButton } from "../../components/Buttons/BackButton";
import CustomButton from "../../components/Buttons/CustomButton";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();
  return (
    <AdminLayout>
      <Stack align={"flex-start"} maxW={"1049px"} gap={"23px"} mt={"60px"}>
        <CustomText text={"Settings- Super Admin  view"} size="16px" w="600" />
        <Box
          p={"34px 0px 220px 36px"}
          borderRadius={"5px"}
          bg={"light.bg"}
          w={"100%"}
        >
          <Flex align={"flex-start"} gap={"69px"}>
            <DetailCard
              title="Username"
              desc="Someone Example"
              desc2="Role: Super Admin"
            />
            <Stack gap={"22px"} align={"flex-start"}>
              <BackButton
                title="Referral Settings  "
                nextP={true}
                onClick={() => navigate("/referral-settings")}
              />
              <BackButton
                title="Account number  "
                nextP={true}
                desc="GTB; 1234567890"
              />
              <CustomButton
                text="Exit"
                bgColor="light.bg"
                hoverBg="light.primary"
              />
            </Stack>
            <Stack gap={"22px"} align={"flex-start"}>
              <BackButton
                title="Privileges  "
                nextP={true}
                desc="Full Access"
              />
              <BackButton
                title="Security  "
                nextP={true}
                desc="2FA Status: ON"
              />
              <CustomButton
                text="Save"
                bgColor="light.bg"
                hoverBg="light.primary"
              />
            </Stack>
          </Flex>
        </Box>
      </Stack>
    </AdminLayout>
  );
};

export default Settings;
