import { Box, Flex } from "@chakra-ui/react";
import { useState } from "react";
import SideBar from "../components/Sidebar";
import Header from "../components/Header";
// import Header from "../components/Header";

const AdminLayout = ({
  children,
  noSidebar,
  text,
  icon,
  textColor,
  maxW,
}: any) => {
  const [isOpen, setIsOpen] = useState(
    localStorage.getItem("isOpen") === "0" ? false : true
  );
  return (
    <Box position={"relative"} mb={"60px"}>
      <Flex>
        {!noSidebar && (
          <>
            {" "}
            <SideBar isOpen={isOpen} setIsOpen={setIsOpen} />
          </>
        )}
        <Box
          p="36px"
          flexGrow={1}
          onClick={() => {
            if (window.innerWidth < 480) {
              setIsOpen(false);
              localStorage.setItem("isOpen", "0");
            }
          }}
        ></Box>
      </Flex>
      <Box
        ml={
          !noSidebar
            ? isOpen
              ? ["100", "300px"]
              : ["100px", "110px", "120px", "120px"]
            : "30px"
        }
        mr={{ base: "10px", md: "30px" }}
        onClick={() => {
          if (window.innerWidth < 480) {
            setIsOpen(false);
            localStorage.setItem("isOpen", "0");
          }
        }}
      >
        <Header text={text} icon={icon} textColor={textColor} maxW={maxW} />
        {children}
      </Box>
    </Box>
  );
};

export default AdminLayout;
