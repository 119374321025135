import { Flex, Image, Stack } from "@chakra-ui/react";
import React from "react";
import CustomText from "../Text/CustomText";
import TextWithEmail from "../Text/TextWithEmail";

const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

const DetailCard = ({
  title,
  desc,
  descColor,
  width,
  textG,
  icon,
  descSize,
  descW,
  fromHome,
  desc2,
  titleSize,
}: any) => {
  const emails = desc.match(emailRegex); // Find emails in description

  let text1 = desc;
  let text2 = "";
  let text3 = "";

  if (emails && emails.length > 0) {
    // Split the text based on the first email
    const [beforeEmail, afterEmail] = desc.split(emails[0]);
    text1 = beforeEmail.trim(); // Text before the first email (trim to remove extra spaces)
    text2 = emails[0]; // First email

    // Remove the "to" from the text after the first email
    const cleanedAfterEmail = afterEmail.replace(/\bto\b\s*/, "").trim();

    // Check if there's a second email
    const secondEmailMatch = cleanedAfterEmail.match(emailRegex);
    if (secondEmailMatch) {
      const [afterFirstEmail] = cleanedAfterEmail.split(secondEmailMatch[0]);
      text3 = secondEmailMatch[0]; // Second email
      text1 += afterFirstEmail.trim(); // Continue first part of the text after the first email
    } else {
      text1 += cleanedAfterEmail; // No second email, just append remaining text to text1
    }
  }

  const isDeposit = desc.toLowerCase().includes("deposit");
  return (
    <Stack w={"100%"} width={width || "240px"}>
      <Flex
        p={"5px 6px"}
        maxW={{ base: "141px", md: "191px" }}
        borderRadius={"5px"}
        bg={"light.bgGray"}
        mb={"-30px"}
        ml={"-10px"}
        position={"relative"}
      >
        <CustomText
          text={title}
          size={titleSize ? titleSize : { base: "11px", md: "13px" }}
          w="600"
          lh={{ base: "14px", md: "17px" }}
        />
      </Flex>
      <Flex
        p={"32px 0px 11px 9px"}
        maxW={width || { base: "180px", md: "240px" }}
        borderRadius={"5px"}
        border={"1px solid"}
        borderColor={"light.border.secondary"}
        bg={"light.bg"}
      >
        {textG ? (
          <Flex align={"center"} gap={"10px"}>
            <TextWithEmail
              text1={text1}
              text2={text2}
              text3={text3}
              w="100%"
              color={
                !fromHome
                  ? "black"
                  : isDeposit
                  ? "light.text.secondary"
                  : "#F00"
              }
            />
            {icon && <Image src={icon} alt="copyIcon" />}
          </Flex>
        ) : (
          <Flex align={"center"} gap={"10px"}>
            <Stack>
              <CustomText
                text={desc}
                color={descColor || "text"}
                size={descSize || { base: "11px", md: "13px" }}
                lh="18px"
                w={descW || "400"}
              />
              {desc2 && (
                <CustomText
                  text={desc2}
                  color={"text"}
                  size={{ base: "11px", md: "13px" }}
                  lh={{ base: "14px", md: "20px" }}
                  w={"400"}
                />
              )}
            </Stack>
            {icon && <Image src={icon} alt="copyIcon" />}
          </Flex>
        )}
      </Flex>
    </Stack>
  );
};

export default DetailCard;
