import React, { useState } from "react";
import AuthLayout from "../../layout/AuthLayout";
import { Image, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import arrow1 from "../../assets/auth/backArrow.svg";
import arrow2 from "../../assets/auth/backArrowDark.svg";
import AuthCardHeader from "../../components/Auth/AuthCardHeader";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import UserInfoInput from "../../components/Auth/UserInfoInput";

const ResetPassword = () => {
  const navigate = useNavigate();
  const arrowIcon = useColorModeValue(arrow1, arrow2);
  const [formData, setFormData] = useState({
    password: "",
  });
  const { password } = formData;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <AuthLayout noAuthNav={true} bg={useColorModeValue("#FDFDFD", "#171918")}>
      <Image src={arrowIcon} alt="arrow" cursor={"pointer"} />
      <AuthCardHeader
        fontSize="43px"
        desFontSize="23px"
        title="Enter your new Password"
        des={"Set your new password to login into your account"}
        desFamily={"Plus Jakarta Sans"}
        desColor={useColorModeValue("#000", "#FFF")}
      />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          navigate("/password-changed");
        }}
      >
        <UserInfoInput
          type={"text"}
          //   placeholder="Password"
          darkBG="transparent"
          name="password"
          value={password}
          onChange={(e: any) => handleChange(e)}
          // border={loginError && "red"}
        />

        <PrimaryButton text={"Submit"} type="submit" />
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;
