import { Box, Flex, Image, Stack } from "@chakra-ui/react";
import CustomText from "../Text/CustomText";
import CopyIcon from "../../assets/copy-icon.svg";

export const SummaryBox = ({
  title,
  desc,
  icon,
  icon2,
  iconTitle,
  descW,
  descSize,
  textD,
  desc2,
  hoverBg,
}: any) => {
  return (
    <Stack
      w={"240px"}
      h={"66px"}
      gap={"4px"}
      _hover={{ bg: hoverBg ? hoverBg : "" }}
      cursor={hoverBg ? "pointer" : "auto"}
      justify={"center"}
      pl={"18px"}
      borderRadius={"5px"}
      border={"1px solid"}
      borderColor={"light.border.secondary"}
    >
      <Flex align={"center"} gap={iconTitle ? "5px" : "0px"}>
        <Image src={iconTitle} />
        <CustomText text={title} size="13px" w="600" lh="17px" />
      </Flex>
      <Flex gap={"5px"}>
        <Box w={icon2 ? "159px" : "177px"}>
          <CustomText
            text={desc}
            size={descSize || "13px"}
            w={descW || "400"}
            lh="18px"
            textD={textD}
          />
          <CustomText text={desc2} size={"11px"} w={"300"} lh="18px" />
        </Box>
        <Flex gap={"5px"} align={"center"}>
          {icon2 && <Image src={icon2} alt="edit-icon" />}
          {icon && <Image src={CopyIcon} alt="copy-icon" />}
        </Flex>
      </Flex>
    </Stack>
  );
};
