import React from "react";
import { Text, Box } from "@chakra-ui/react";

const TextWithEmail = ({ text1, text2, text3, size, color, w }: any) => {
  return (
    <Box
      color={color || "light.redColor"}
      fontSize={size || "13px"}
      fontWeight={"500"}
      fontFamily={"Plus Jakarta Sans"}
      textAlign={"left"}
      w={w || "532px"}
    >
      <Text as="span">{text1} </Text>
      <Text as="span" textDecoration="underline">
        {text2}{" "}
      </Text>
      {text3 && (
        <>
          to{" "}
          <Text as="span" textDecoration="underline">
            {text3}
          </Text>
        </>
      )}
    </Box>
  );
};

export default TextWithEmail;
