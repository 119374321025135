import { Flex } from "@chakra-ui/react";
import React from "react";
import { SummaryBox } from "./SummaryBox";

const UserDetailHeader = ({ date, email, id }: any) => {
  return (
    <Flex align={"center"} gap={"71px"}>
      <SummaryBox title="Signup Date" desc={`${date}, 05:47 PM`} />
      <SummaryBox
        title="User Email Address"
        desc={email}
        icon={true}
        textD={true}
      />
      <SummaryBox title="Payremit ID" desc={id} icon={true} />
    </Flex>
  );
};

export default UserDetailHeader;
