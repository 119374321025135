import SolIcon from "../assets/sol-icon.svg";
import UsdtIcon from "../assets/usdt-icon.svg";
import TonIcon from "../assets/ton-icon.svg";
import EthIcon from "../assets/eth-icon.svg";
import BtcIcon from "../assets/btc-icon.svg";
import TrxIcon from "../assets/trx-icon.svg";
import UsdcIcon from "../assets/usdc-icon.svg";
import TonLgIcon from "../assets/ton-lg-icon.svg";
import BtcLgIcon from "../assets/btc-lg-icon.svg";
import TrxLgcIcon from "../assets/trx-lg-icon.svg";
import SolLgcIcon from "../assets/sol-lg-icon.svg";
import EthLgcIcon from "../assets/eth-lg-icon.svg";

export const TRANSACTIONS = [
  {
    date: "18 Sept 2024, 04:30 PM",
    description: "Withdrawal from someone@gmail.com to example@gmail.com",
    amount: "45.00345 USDt",
    reference: "1234abcd5678efgh9012ijkl",
    recipientAccount: {
      bank: "Example Bank",
      accountNumber: "1234567890",
      accountName: "John Doe",
    },
    currentBalance: "100,000.00 USD",
    bgColor: "teal.300",
  },
  {
    date: "18 Sept 2024, 10:15 AM",
    description: "BTC Withdrawal by someone@gmail.com",
    amount: "0.00345 BTC",
    reference: "4321abcd5678efgh9012ijkl",
    recipientAccount: {
      bank: "Example Crypto Wallet",
      accountNumber: "BTC1234567890",
      accountName: "John Doe",
    },
    currentBalance: "2 BTC",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 12:20 PM",
    description: "TON Withdrawal by someoneelse@gmail.com",
    amount: "45.00345 TON",
    reference: "abcdef1234567890",
    recipientAccount: {
      bank: "TON Wallet",
      accountNumber: "TON1234567890",
      accountName: "Jane Doe",
    },
    currentBalance: "10 TON",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 03:10 PM",
    description: "TRX Withdrawal by someoneelse@gmail.com",
    amount: "50 TRX",
    reference: "ijklmnop12345678",
    recipientAccount: {
      bank: "TRX Wallet",
      accountNumber: "TRX1234567890",
      accountName: "Jane Doe",
    },
    currentBalance: "200 TRX",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 06:05 PM",
    description: "ETH Withdrawal by someoneelse@gmail.com",
    amount: "0.00345 ETH",
    reference: "1234efgh5678ijkl9012mnop",
    recipientAccount: {
      bank: "ETH Wallet",
      accountNumber: "ETH1234567890",
      accountName: "John Doe",
    },
    currentBalance: "1 ETH",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 09:45 AM",
    description: "SOL Withdrawal by someoneelse@gmail.com",
    amount: "45.00345 SOL",
    reference: "mnopabcd12345678",
    recipientAccount: {
      bank: "SOL Wallet",
      accountNumber: "SOL1234567890",
      accountName: "John Doe",
    },
    currentBalance: "100 SOL",
    bgColor: "red.100",
  },
  {
    date: "5 Apr 2024, 05:47 PM",
    description: "NGN Withdrawal from someoneelse@gmail.com",
    amount: "₦250,000.00",
    reference: "669b77d034621bd21206a48d",
    recipientAccount: {
      bank: "First Bank",
      accountNumber: "123456789",
      accountName: "John Doe",
    },
    currentBalance: "₦1,000,000",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 11:30 AM",
    description: "NGN Deposit from someoneelse@gmail.com",
    amount: "₦250,000.00",
    reference: "12345678abcd1234",
    recipientAccount: {
      bank: "Second Bank",
      accountNumber: "987654321",
      accountName: "Jane Doe",
    },
    currentBalance: "₦1,500,000",
    bgColor: "green.100",
  },
  {
    date: "18 Sept 2024, 04:00 PM",
    description: "NGN Deposit from someoneelse@gmail.com",
    amount: "₦250,000.00",
    reference: "abcd1234567890efgh",
    recipientAccount: {
      bank: "Third Bank",
      accountNumber: "135792468",
      accountName: "Jane Doe",
    },
    currentBalance: "₦1,750,000",
    bgColor: "green.100",
  },
  {
    date: "18 Sept 2024, 02:30 PM",
    description: "NGN Withdrawal from someoneelse@gmail.com",
    amount: "₦250,000.00",
    reference: "1234abcd5678efghijkl",
    recipientAccount: {
      bank: "Fourth Bank",
      accountNumber: "2468101214",
      accountName: "John Doe",
    },
    currentBalance: "₦2,000,000",
    bgColor: "red.100",
  },
];

export const TRANSACTION_HISTORY = [
  {
    date: "18 Sept 2024, 04:30 PM",
    description: "Withdrawal from someone@gmail.com to example@gmail.com",
    amount: "45.00345 USDt",
    reference: "1234abcd5678efgh9012ijkl",
    recipientAccount: {
      bank: "Example Bank",
      accountNumber: "1234567890",
      accountName: "John Doe",
    },
    currentBalance: "100,000.00 USD",
    bgColor: "teal.300",
  },
  {
    date: "18 Sept 2024, 10:15 AM",
    description: "Naira Swap from patrickfloyd112@02concave.com",
    amount: "0.00345 BTC",
    reference: "4321abcd5678efgh9012ijkl",
    recipientAccount: {
      bank: "Example Crypto Wallet",
      accountNumber: "1234567890",
      accountName: "John Doe",
    },
    currentBalance: "2 BTC",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 12:20 PM",
    description: "Withdrawal to bank from patrickfloyd112@02concave.com",
    amount: "45.00345 TON",
    reference: "abcdef1234567890",
    recipientAccount: {
      bank: "TON Wallet",
      accountNumber: "1234567890",
      accountName: "Jane Doe",
    },
    currentBalance: "10 TON",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 03:10 PM",
    description: "Deposit from kizzbrown51@gmail.com",
    amount: "50 TRX",
    reference: "ijklmnop12345678",
    recipientAccount: {
      bank: "TRX Wallet",
      accountNumber: "1234567890",
      accountName: "Jane Doe",
    },
    currentBalance: "200 TRX",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 06:05 PM",
    description: "sol.clinton@yahoo.com Transfer To dami4destiny@gmail.com",
    amount: "0.00345 ETH",
    reference: "1234efgh5678ijkl9012mnop",
    recipientAccount: {
      bank: "ETH Wallet",
      accountNumber: "1234567890",
      accountName: "John Doe",
    },
    currentBalance: "1 ETH",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 09:45 AM",
    description: "NGN Withdrawal by someonelese@gmail.com",
    amount: "45.00345 SOL",
    reference: "mnopabcd12345678",
    recipientAccount: {
      bank: "SOL Wallet",
      accountNumber: "1234567890",
      accountName: "John Doe",
    },
    currentBalance: "100 SOL",
    bgColor: "red.100",
  },
  {
    date: "5 Apr 2024, 05:47 PM",
    description: "NGN Withdrawal by someonelese@gmail.com",
    amount: "₦250,000.00",
    reference: "669b77d034621bd21206a48d",
    recipientAccount: {
      bank: "First Bank",
      accountNumber: "123456789",
      accountName: "John Doe",
    },
    currentBalance: "₦1,000,000",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 11:30 AM",
    description: "NGN Deposit by someonelese@gmail.com",
    amount: "₦250,000.00",
    reference: "12345678abcd1234",
    recipientAccount: {
      bank: "Second Bank",
      accountNumber: "987654321",
      accountName: "Jane Doe",
    },
    currentBalance: "₦1,500,000",
    bgColor: "green.100",
  },
  {
    date: "18 Sept 2024, 04:00 PM",
    description: "NGN Withdrawal by someonelese@gmail.com",
    amount: "₦250,000.00",
    reference: "abcd1234567890efgh",
    recipientAccount: {
      bank: "Third Bank",
      accountNumber: "135792468",
      accountName: "Jane Doe",
    },
    currentBalance: "₦1,750,000",
    bgColor: "green.100",
  },
  {
    date: "18 Sept 2024, 02:30 PM",
    description: "NGN Withdrawal by someonelese@gmail.com",
    amount: "₦250,000.00",
    reference: "1234abcd5678efghijkl",
    recipientAccount: {
      bank: "Fourth Bank",
      accountNumber: "2468101214",
      accountName: "John Doe",
    },
    currentBalance: "₦2,000,000",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 02:30 PM",
    description: "Utilities- IKEDC- prepaid",
    amount: "NGN 20,000",
    reference: "1234abcd5678efghijkl",
    recipientAccount: {
      bank: "Fourth Bank",
      accountNumber: "2468101214",
      accountName: "John Doe",
    },
    currentBalance: "₦2,000,000",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 02:30 PM",
    description: "Utilities- DSTV",
    amount: "NGN 20,000",
    reference: "1234abcd5678efghijkl",
    recipientAccount: {
      bank: "Fourth Bank",
      accountNumber: "2468101214",
      accountName: "John Doe",
    },
    currentBalance: "₦2,000,000",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 02:30 PM",
    description: "Utilities- MTN Data",
    amount: "NGN 20,000",
    reference: "1234abcd5678efghijkl",
    recipientAccount: {
      bank: "Fourth Bank",
      accountNumber: "2468101214",
      accountName: "John Doe",
    },
    currentBalance: "₦2,000,000",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 02:30 PM",
    description: "Utilities- MTN",
    amount: "NGN 20,000",
    reference: "1234abcd5678efghijkl",
    recipientAccount: {
      bank: "Fourth Bank",
      accountNumber: "2468101214",
      accountName: "John Doe",
    },
    currentBalance: "₦2,000,000",
    bgColor: "red.100",
  },
];

export const EXCHANGE_RATES = [
  { icon: SolIcon, currency: "SOL", buy: 1290, sell: 1290 },
  { icon: UsdtIcon, currency: "USDT", buy: 490, sell: 490 },
  { icon: TonIcon, currency: "TON", buy: 810.03, sell: 810.03 },
  { icon: EthIcon, currency: "ETH", buy: 154498, sell: 154498 },
  { icon: BtcIcon, currency: "BTC", buy: 10682911, sell: 10682911 },
  { icon: TrxIcon, currency: "TRX", buy: 10682911, sell: 10682911 },
  { icon: UsdcIcon, currency: "USDC", buy: 10682911, sell: 10682911 },
];

export const CRYPT_PRICES = [
  { icon: TonLgIcon, coin: "TON", price: 10810.03, change: "+0.67%" },
  { icon: BtcLgIcon, coin: "BTC", price: 67029, change: "+0.50%" },
  { icon: EthLgcIcon, coin: "ETH", price: 5154498, change: "+0.50%" },
  { icon: TrxLgcIcon, coin: "TRX", price: 218.69, change: "+0.50%" },
  { icon: SolLgcIcon, coin: "SOL", price: 7510, change: "+0.50%" },
];

export const USERS = [
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level02",
    status: "disabled",
    validationStatus: "no data submitted",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level02",
    status: "enabled",
    validationStatus: "Pending",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level01",
    status: "disabled",
    validationStatus: "In progress",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level02",
    status: "disabled",
    validationStatus: "Pending",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level03",
    status: "enabled",
    validationStatus: "Pending",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level02",
    status: "enabled",
    validationStatus: "Pending",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level02",
    status: "enabled",
    validationStatus: "Pending",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level01",
    status: "enabled",
    validationStatus: "Pending",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level02",
    status: "enabled",
    validationStatus: "Pending",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level03",
    status: "disabled",
    validationStatus: "Pending",
  },
];

export const KYC = [
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level02",
    KycStatus: "Pending",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level02",
    KycStatus: "Pending",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level01",
    KycStatus: "Rejected",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level02",
    KycStatus: "Rejected",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level03",
    KycStatus: "Approved",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level02",
    KycStatus: "Approved",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level02",
    KycStatus: "Approved",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level01",
    KycStatus: "Rejected",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level02",
    KycStatus: "Pending",
  },
  {
    date: "5/1/2024",
    name: "Benedict Omem",
    email: "omemgbojib@gmail.com",
    level: "Level03",
    KycStatus: "Pending",
  },
];

export const USER_CRYPTO_DATA1 = [
  {
    icon: BtcIcon, // Placeholder for the Bitcoin icon
    name: "BTC",
    address: "1Lbs****4ZnX7",
    balance: "0.0027",
  },
  {
    icon: TrxIcon, // Placeholder for the TRX icon
    name: "TRX",
    address: "1Lbs****4ZnX7",
    balance: "1.0025",
  },
  {
    icon: UsdtIcon, // Placeholder for the USDT icon
    name: "USDT",
    address: "1Lbs****4ZnX7",
    balance: "99.8002",
  },
  {
    icon: UsdcIcon, // Placeholder for the USDC icon
    name: "USDC",
    address: "1Lbs****4ZnX7",
    balance: "99.8002",
  },
];

export const USER_CRYPTO_DATA2 = [
  {
    icon: EthIcon, // Placeholder for the Ethereum icon
    name: "ETH",
    address: "1Lbs****4ZnX7",
    balance: "0.0001",
  },
  {
    icon: SolIcon, // Placeholder for the Solana icon
    name: "SOL",
    address: "1Lbs****4ZnX7",
    balance: "0.00013",
  },
  {
    icon: TonIcon, // Placeholder for the TON icon
    name: "TON",
    address: "1Lbs****4ZnX7",
    balance: "99.8002",
  },
];

export const USER_TRANSACTION_HISTORY = [
  {
    date: "18 Sept 2024, 04:30 PM",
    description: "USDt Withdrawal",
    amount: "45.00345 USDt",
    reference: "1234abcd5678efgh9012ijkl",
    recipientAccount: {
      bank: "Example Bank",
      accountNumber: "1234567890",
      accountName: "John Doe",
    },
    currentBalance: "100,000.00 USD",
    bgColor: "teal.300",
  },
  {
    date: "18 Sept 2024, 10:15 AM",
    description: "Naira to Crypto Swap ",
    amount: "0.00345 BTC",
    reference: "4321abcd5678efgh9012ijkl",
    recipientAccount: {
      bank: "Example Crypto Wallet",
      accountNumber: "1234567890",
      accountName: "John Doe",
    },
    currentBalance: "2 BTC",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 12:20 PM",
    description: "Withdrawal to bank ",
    amount: "45.00345 TON",
    reference: "abcdef1234567890",
    recipientAccount: {
      bank: "TON Wallet",
      accountNumber: "1234567890",
      accountName: "Jane Doe",
    },
    currentBalance: "10 TON",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 03:10 PM",
    description: "DSTV Premium plus package",
    amount: "50 TRX",
    reference: "ijklmnop12345678",
    recipientAccount: {
      bank: "TRX Wallet",
      accountNumber: "1234567890",
      accountName: "Jane Doe",
    },
    currentBalance: "200 TRX",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 06:05 PM",
    description: "TON Transfer to dami4destiny@gmail.com",
    amount: "0.00345 ETH",
    reference: "1234efgh5678ijkl9012mnop",
    recipientAccount: {
      bank: "ETH Wallet",
      accountNumber: "1234567890",
      accountName: "John Doe",
    },
    currentBalance: "1 ETH",
    bgColor: "red.100",
  },
  {
    date: "18 Sept 2024, 09:45 AM",
    description: "NGN Withdrawal ",
    amount: "45.00345 SOL",
    reference: "mnopabcd12345678",
    recipientAccount: {
      bank: "SOL Wallet",
      accountNumber: "1234567890",
      accountName: "John Doe",
    },
    currentBalance: "100 SOL",
    bgColor: "red.100",
  },
  {
    date: "5 Apr 2024, 05:47 PM",
    description: "Referral claim",
    amount: "₦250,000.00",
    reference: "669b77d034621bd21206a48d",
    recipientAccount: {
      bank: "First Bank",
      accountNumber: "123456789",
      accountName: "John Doe",
    },
    currentBalance: "₦1,000,000",
    bgColor: "red.100",
  },
];
export const USER_ACTIVITY = [
  {
    date: "5/4/2024, 05:47 PM",
    category: "Login",
    action: "New device login",
  },
  {
    date: "4/4/2024, 05:47 PM",
    category: "Bank",
    action: "Bank account removed",
  },
  {
    date: "3/4/2024, 05:47 PM",
    category: "Bank",
    action: "New Bank account added",
  },
  {
    date: "2/4/2024, 05:47 PM",
    category: "Security",
    action: "Password reset",
  },
  {
    date: "1/4/2024, 05:47 PM",
    category: "IP Address change",
    action: "192.158.1.38",
  },
];

export const USER_REFERRAL_ACTIVITY = [
  {
    date: "5/4/2024, 05:47 PM",
    name: "John Simon",
    email: "someone@example.com",
  },
  {
    date: "4/4/2024, 10:45 AM",
    name: "John Money",
    email: "c9741339@gmail.com",
  },
  {
    date: "3/4/2024, 10:45 AM",
    name: "John Mark",
    email: "c9741339@gmail.com",
  },
  {
    date: "2/4/2024, 10:45 AM",
    name: "Mark Femi",
    email: "c9741339@gmail.com",
  },
  {
    date: "1/4/2024, 10:45 AM",
    name: "John Mark",
    email: "c9741339@gmail.com",
  },
];
