import {
  Box,
  Flex,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import PrimaryText from "./Text/PrimaryText";

// Default icons
import SearchIcon from "../assets/search.svg";
import SearchBlack from "../assets/search-black.svg";
import CloseFilter from "../assets/close-filter.svg";
import FilterIcon from "../assets/filter-icon.svg";

// Styles for date picker
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

// Reusable SearchHeader component
const SearchHeader = ({
  title,
  searchPlaceholder,
  filterItems,
  hoverMenuItems,
}: {
  title: string;
  searchPlaceholder: string;
  filterItems: string[];
  hoverMenuItems: { [key: string]: string[] };
}) => {
  const [isSearching, setIsSearching] = useState(false);
  const [currency, setCurrency] = useState(""); // Default currency
  const [searchQuery, setSearchQuery] = useState(""); // Search input value
  const [hoveredFilterItem, setHoveredFilterItem] = useState<string | null>(
    null
  ); // Track hovered filter item
  const [showDateRange, setShowDateRange] = useState(false); // Control date range picker
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSearchClick = () => {
    setIsSearching(!isSearching); // Toggle search input
  };

  const handleInputBlur = () => {
    setIsSearching(false); // Revert to title on blur
  };

  const handleCurrencyChange = (currencyType: string) => {
    if (currencyType === "Custom") {
      setShowDateRange(true); // Show date range picker when "Custom" is clicked
    } else {
      setCurrency(currencyType); // Set currency filter for other types
    }
  };

  const handleDateRangeChange = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    setSelectedRange({ ...selectedRange, startDate, endDate });
    setShowDateRange(false); // Hide date picker after selecting
    setCurrency(
      `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
    ); // Set selected date range as the value for "Custom"
  };

  const handleSearchInputChange = (event: any) => {
    setSearchQuery(event.target.value); // Update search input
  };

  return (
    <Flex align={"center"} gap={"12px"}>
      <Flex
        w={{
          base: "100%",
          sm: "300px",
          md: isSearching ? "545px" : "378.941px",
        }}
        h={"49px"}
        flexShrink={"0"}
        borderRadius={"20px 20px 0px 0px"}
        bg={"#FFF"}
        pt={"12px"}
        pl={{ base: "12px", md: "24px" }}
        gap={"11px"}
        align={"center"}
      >
        {isSearching ? (
          <Flex align={"center"} gap={"11px"} w={"100%"}>
            <Flex
              w={{ base: "100%", md: "462px" }}
              borderRadius={"10px"}
              bg={"#F2F2F2"}
              justify={"space-between"}
              align={"center"}
              p={"6px 12px"}
            >
              <Flex align={"center"} gap={{ base: "10px", md: "25px" }}>
                <Image
                  src={SearchBlack}
                  w={{ base: "10px", md: "20px" }}
                  h={{ base: "10px", md: "20px" }}
                  cursor="pointer"
                  onClick={handleSearchClick} // Handle search icon click
                />
                <Input
                  placeholder={searchPlaceholder}
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  onBlur={handleInputBlur} // Revert on blur
                  border="none"
                  bg="transparent"
                  variant={"unstyled"}
                  w={{ md: "300px" }}
                  color={"#000"}
                  fontFamily={"Plus Jakarta Sans"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  lineHeight={"25px"}
                  _placeholder={{
                    color: "black",
                    fontFamily: "Plus Jakarta Sans",
                    fontSize: "11px",
                    fontStyle: "italic",
                    fontWeight: "400",
                    lineHeight: "25px",
                  }}
                />
              </Flex>
              <Menu>
                <MenuButton>
                  <Image
                    src={FilterIcon}
                    w={"20px"}
                    h={"20px"}
                    cursor="pointer"
                  />
                </MenuButton>
                <MenuList p={"0px"}>
                  <MenuItem _hover={{ bg: "none" }} fontWeight={"600"}>
                    Search By
                  </MenuItem>

                  {/* Dynamic filter items with hover dropdown */}
                  {filterItems.map((filterItem, index) => (
                    <MenuItem
                      key={index}
                      _hover={{ bg: "#67C475" }}
                      onMouseEnter={() => setHoveredFilterItem(filterItem)} // Show dropdown on hover
                      onMouseLeave={() => setHoveredFilterItem(null)} // Hide dropdown when not hovering
                    >
                      <Flex justify="space-between" align="center" w="100%">
                        {filterItem}
                      </Flex>

                      {/* Hover dropdown based on the filter item */}
                      <Box
                        position="absolute"
                        left="100%"
                        top="9"
                        bg="white"
                        w="150px"
                        boxShadow="md"
                        visibility={
                          hoveredFilterItem === filterItem
                            ? "visible"
                            : "hidden"
                        } // Show only if the current filter item is hovered
                      >
                        {hoverMenuItems[filterItem]?.map((hoverItem, idx) => (
                          <MenuItem
                            key={idx}
                            _hover={{ bg: "#67C475" }}
                            bg={"none"}
                            onClick={() => handleCurrencyChange(hoverItem)}
                          >
                            {hoverItem}
                          </MenuItem>
                        ))}
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Flex>
            <Image
              src={CloseFilter}
              cursor="pointer"
              onClick={handleSearchClick} // Close search
            />
          </Flex>
        ) : (
          <Flex align={"center"} gap={"11px"} w={"100%"}>
            <Image
              src={SearchIcon}
              w={"20px"}
              h={"20px"}
              cursor="pointer"
              onClick={handleSearchClick} // Open search input
            />
            <PrimaryText text={title} w="600" />
          </Flex>
        )}
      </Flex>

      {/* Display selected currency or date range */}
      {currency && (
        <Box
          h={"40px"}
          p={"7px 18px"}
          borderRadius={"40px"}
          bg={"light.bg"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
        >
          <PrimaryText text={currency} />
          <Image
            src={CloseFilter}
            alt=""
            onClick={() => setCurrency("")} // Clear selected currency
          />
        </Box>
      )}

      {/* Date Range Picker Modal */}
      {showDateRange && (
        <Box position="absolute" zIndex={100} bg="white" p={4} boxShadow="md">
          <DateRangePicker
            ranges={[selectedRange]}
            onChange={handleDateRangeChange}
            moveRangeOnFirstSelection={false}
          />
        </Box>
      )}
    </Flex>
  );
};

export default SearchHeader;
