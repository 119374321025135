import { Text } from "@chakra-ui/react";
import React from "react";

const CustomText = ({
  text,
  w,
  size,
  color,
  lh,
  fontFamily,
  fontFeature,
  display,
  textD,
}: any) => {
  return (
    <Text
      sx={{
        ...(fontFeature && {
          fontFeatureSettings: "'ss01' on, 'cv01' on, 'cv11' on",
        }),
      }}
      color={color || "text"}
      fontSize={size || "14px"}
      fontWeight={w || "400"}
      lineHeight={lh || "normal"}
      fontFamily={fontFamily || "Plus Jakarta Sans"}
      display={display || "block"}
      textDecoration={textD ? "underline" : "none"}
    >
      {text}
    </Text>
  );
};

export default CustomText;
