import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AdminLayout from "../../layout";
import { Box, Flex, Image, Stack } from "@chakra-ui/react";
import CustomText from "../../components/Text/CustomText";
import UserDetailHeader from "../../components/Cards/UserDetailHeader";
import { BackButton } from "../../components/Buttons/BackButton";
import { SummaryBox } from "../../components/Cards/SummaryBox";
import CustomButton from "../../components/Buttons/CustomButton";
import UserImg from "../../assets/user-pic.png";

const KycSettings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state;

  return (
    <AdminLayout>
      <Stack align={"flex-start"} maxW={"1049px"} gap={"23px"}>
        <CustomText text={"Kyc Settings"} size="16px" w="600" />
        <Box
          p={"34px 0px 46px 36px"}
          mt={"23px"}
          borderRadius={"5px"}
          bg={"light.bg"}
          w={"100%"}
        >
          <UserDetailHeader
            date={userData?.date}
            email={userData?.email}
            id={"pay-60001987"}
          />
          <Flex gap={"73px"} align={"flex-start"} mt={"24px"}>
            <Stack align={"flex-start"} gap={"22px"}>
              <Image src={UserImg} alt="user-pic" w={"240px"} h={"154px"} />
              <SummaryBox
                title="Phone Number"
                desc={`0803 000 8001`}
                icon={true}
              />
              <BackButton
                title="Exit to main user directory"
                onClick={() => navigate("/users")}
              />
            </Stack>
            <Stack align={"flex-start"} gap={"22px"}>
              {userData?.validationStatus !== "no data submitted" && (
                <>
                  <SummaryBox
                    title="NIN Number"
                    desc={`91731297555`}
                    icon={true}
                  />
                  <SummaryBox
                    title="BVN Number"
                    desc={`2213456780`}
                    icon={true}
                  />
                </>
              )}
              <BackButton
                title="Exit to user summary page"
                onClick={() => navigate("/user-summary")}
              />
            </Stack>
            <Stack align={"flex-start"} gap={"22px"}>
              {userData?.validationStatus !== "no data submitted" && (
                <SummaryBox
                  title="House Address"
                  desc={`No 27 Highstar Street
Sapele road`}
                  icon={true}
                />
              )}
              <SummaryBox
                title="NIN and BVN Validation Status"
                hoverBg="light.primary"
                desc={
                  userData?.validationStatus === "Pending"
                    ? `${userData?.validationStatus}. Click to validate`
                    : `${userData?.validationStatus}`
                }
              />

              <BackButton
                title="Exit to KYC directory"
                onClick={() => navigate("/user-summary")}
                nextP={true}
              />
            </Stack>
          </Flex>
        </Box>
        {userData?.validationStatus === "In progress" && (
          <Flex align={"center"} gap={"2px"}>
            <CustomText text="NIN results- " size="16px" w="500" />
            <CustomText text="Success" size="16px" w="500" color="#67C475" />
          </Flex>
        )}
        {userData?.validationStatus === "In progress" && (
          <Box
            p={"95px 0 31px 40px"}
            borderRadius={"5px"}
            bg={"light.bg"}
            w={"100%"}
          >
            <Flex gap={"73px"} align={"flex-start"}>
              <Stack gap={"22px"} align={"flex-start"}>
                <Image src={UserImg} alt="user-pic" w={"240px"} h={"154px"} />
                <SummaryBox title="First name" desc={`DAMX TON`} icon={true} />
                <SummaryBox title="Last name" desc={`DAMX TON`} icon={true} />
                <SummaryBox
                  title="Phone number"
                  desc={`0803 000 8001`}
                  icon={true}
                />
              </Stack>
              <Stack gap={"22px"} align={"flex-start"}>
                <SummaryBox title="Gender" desc={`Male`} icon={true} />
                <SummaryBox
                  title="Date of Birth"
                  desc={`1999-08-17`}
                  icon={true}
                />
                <SummaryBox
                  title="Address"
                  desc={`No 27 Highstar Street
Sapele road`}
                  icon={true}
                />
              </Stack>
              <Stack gap={"22px"} align={"flex-start"}>
                <CustomButton
                  bgColor="light.bg"
                  text="Message Successful"
                  textColor="#67C475"
                />
                <CustomButton
                  bgColor="light.bg"
                  text="Save"
                  hoverBg="light.primary"
                />
                <BackButton title="Validate BVN" nextP={true} />
              </Stack>
            </Flex>
          </Box>
        )}
      </Stack>
    </AdminLayout>
  );
};

export default KycSettings;
