import { useColorMode, useColorModeValue, Image } from "@chakra-ui/react";
import lightSwitch from "../assets/auth/lightswitch.png";
import darkSwitch from "../assets/auth/darkswitch.png";

export default function ThemeToggle({ ml }: any) {
  const { toggleColorMode } = useColorMode();
  const switchSrc = useColorModeValue(lightSwitch, darkSwitch);

  return (
    <Image
      src={switchSrc}
      cursor={"pointer"}
      h={{ base: "32px", lg: "52px" }}
      ml={ml}
      onClick={toggleColorMode}
    />
  );
}
