import React, { useState } from "react";
import AdminLayout from "../../layout";
import { Center, Flex, Image, Select, Stack } from "@chakra-ui/react";
import CustomText from "../../components/Text/CustomText";
import BtcBalanceText from "../../assets/Overall User BTC Balance.svg";
import UserUsdtText from "../../assets/Overall User USDt Balance.svg";
import UserTonText from "../../assets/Overall User TON Balance.svg";
import UserTrxText from "../../assets/Overall User TRX Balance.svg";
import UserNgnText from "../../assets/Overall User NGN Balance.svg";
import UserEthText from "../../assets/Overall User ETH Balance.svg";
import UserSolText from "../../assets/Overall User SOL Balance.svg";
import UserUsdcText from "../../assets/Overall User USDC Balance.svg";
import SearchHeader from "../../components/SearchHeader";
import { RowCardDetailHistory } from "../../components/RowCardDetail";
import RowCard from "../../components/RowCard";
import PrimaryText from "../../components/Text/PrimaryText";
import LeftArrow from "../../assets/left-arrow.svg";
import RightArrow from "../../assets/right-arrow.svg";
import { TRANSACTION_HISTORY } from "../../utils/constants";
import ExportButton from "../../components/Buttons/ExportButton";

const TransactionHistory = () => {
  const [pageSize, setPageSize] = useState(9); // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [selectedTransaction, setSelectedTransaction] = useState(null); // Track selected transaction

  const totalPages = Math.ceil(TRANSACTION_HISTORY.length / pageSize);

  // Calculate the start and end indices of the transactions to show for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentTransactions = TRANSACTION_HISTORY.slice(startIndex, endIndex);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle Previous Page
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleTransactionClick = (transaction: any) => {
    // If the clicked transaction is already selected, deselect it
    if (selectedTransaction === transaction) {
      setSelectedTransaction(null);
    } else {
      setSelectedTransaction(transaction); // Select the clicked transaction
    }
  };

  return (
    <AdminLayout>
      {selectedTransaction ? (
        <></>
      ) : (
        <>
          <Flex align={"flex-start"} gap={"15px"} flexWrap={"wrap"}>
            <StatsCard textIcon={BtcBalanceText} text="30,693.43" />
            <StatsCard
              textIcon={UserUsdtText}
              text="30,693.43"
              p={"14px 13.5px 14px 10.5px"}
            />
            <StatsCard
              textIcon={UserTonText}
              text="30,693.43"
              p={"14px 19.5px 14px 10.5px"}
            />
            <StatsCard
              textIcon={UserTrxText}
              text="30,693.43"
              p={"14px 24.5px 14px 10.5px"}
            />
          </Flex>
          <Flex
            align={"flex-start"}
            gap={"15px"}
            mt={"20px"}
            mb={"35px"}
            flexWrap={"wrap"}
          >
            <StatsCard
              textIcon={UserNgnText}
              text="30,693.43"
              p={"14px 16.5px 14px 10.5px"}
            />
            <StatsCard
              textIcon={UserEthText}
              text="30,693.43"
              p={"14px 22.5px 14px 10.5px"}
            />
            <StatsCard
              textIcon={UserSolText}
              text="30,693.43"
              p={"14px 19.5px 14px 10.5px"}
            />
            <StatsCard
              textIcon={UserUsdcText}
              text="30,693.43"
              p={"14px 7.5px 14px 10.5px"}
            />
          </Flex>
        </>
      )}
      <Flex align={"center"} justify={"space-between"} maxW={"1009px"}>
        <SearchHeader
          title="Transaction History"
          searchPlaceholder="Search by email or transaction reference or user-ID"
          filterItems={[
            "Transaction Date",
            "Transaction Type",
            "Currency Type",
            "Transaction Status",
          ]}
          hoverMenuItems={{
            "Transaction Date": [
              "Date Range",
              "24 hours",
              "Two weeks ago",
              "This month",
              "Last 12 months",
              "Custom",
              "All time",
            ],
            "Transaction Type": [
              "Swap",
              "Utilities",
              "Deposits",
              "Withdrawals",
            ],
            "Currency Type": ["NGN", "USD", "BTC", "ETH", "TRX"],
            "Transaction Status": ["Completed", "Failed", "Rejected"],
          }}
        />

        <ExportButton />
      </Flex>
      <Stack align={"flex-start"} gap={"11px"} overflowX={"scroll"}>
        {currentTransactions.map((trx: any) => (
          <>
            {selectedTransaction === trx ? (
              <RowCardDetailHistory
                date={trx.date}
                description={trx.description}
                amount={trx.amount}
                reference={trx.reference}
                recipientAccount={trx.recipientAccount}
                currentBal={trx.currentBalance}
                onClick={() => setSelectedTransaction(null)}
              />
            ) : (
              <RowCard
                date={trx.date}
                text={trx.description}
                amount={trx.amount}
                onClick={() => handleTransactionClick(trx)}
              />
            )}
          </>
        ))}
        <Flex
          align={"center"}
          justify={"space-between"}
          w={"100%"}
          maxW={"1009px"}
        >
          <Flex align={"center"} gap={"10px"}>
            <PrimaryText text="Showing" />
            <Select
              variant={"primary"}
              w="70px"
              h="44px"
              borderRadius={"5px"}
              bg={"light.bg"}
              boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.2)"}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setCurrentPage(1); // Reset to page 1 when page size changes
              }}
              value={pageSize}
              textColor={"light.text.primary"}
              fontSize={"14px"}
            >
              {[9, 10, 50, 500, 1000, 5000].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex align={"center"} gap={"20px"}>
            {/* Previous Button */}
            <Center
              w={"32px"}
              h={"44px"}
              borderRadius={"5px"}
              bg={"light.bg"}
              boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.02)"}
              cursor={currentPage === 1 ? "not-allowed" : "pointer"}
              onClick={handlePreviousPage}
            >
              <Image
                src={LeftArrow}
                alt="previous page"
                opacity={currentPage === 1 ? 0.5 : 1}
              />
            </Center>

            {/* Next Button */}
            <Center
              w={"32px"}
              h={"44px"}
              borderRadius={"5px"}
              bg={"light.bg"}
              boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.02)"}
              cursor={currentPage === totalPages ? "not-allowed" : "pointer"}
              onClick={handleNextPage}
            >
              <Image
                src={RightArrow}
                alt="next page"
                opacity={currentPage === totalPages ? 0.5 : 1}
              />
            </Center>
          </Flex>
        </Flex>
      </Stack>
    </AdminLayout>
  );
};

export default TransactionHistory;

const StatsCard = ({ textIcon, text, p }: any) => {
  return (
    <Flex
      align={"center"}
      p={p || "14px 21.5px 14px 10.5px"}
      borderRadius={"10px"}
      border={"0.3px solid"}
      borderColor={"light.remainder"}
    >
      <Stack justify={"center"} align={"flex-start"} gap={"5px"}>
        <Image src={textIcon} alt="text icon" />
        <CustomText text={text} fontFeature={true} size="22px" lh="36px" />
      </Stack>
    </Flex>
  );
};
