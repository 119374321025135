import { Text } from "@chakra-ui/react";
import React from "react";

const PrimaryText = ({ text, w }: any) => {
  return (
    <Text
      color={"text"}
      fontSize={{ base: "10px", md: "14px" }}
      fontWeight={w || "400"}
      lineHeight={"25px"}
      fontFamily={"Plus Jakarta Sans"}
    >
      {text}
    </Text>
  );
};

export default PrimaryText;
