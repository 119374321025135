import React, { useState } from "react";
import AdminLayout from "../../layout";
import { Box, Flex, Image, Stack } from "@chakra-ui/react";
import CustomText from "../../components/Text/CustomText";
import UserImg from "../../assets/user-pic.png";
import AccountPic from "../../assets/account-pic.svg";
import LeftArrow from "../../assets/left-arrow.svg";
import RightArrow from "../../assets/right-arrow.svg";
import NGNFlag from "../../assets/ngn-flag.svg";
import CopyIcon from "../../assets/copy-icon.svg";
import { SummaryBox } from "../../components/Cards/SummaryBox";
import { useLocation, useNavigate } from "react-router-dom";
import {
  USER_CRYPTO_DATA1,
  USER_CRYPTO_DATA2,
  USER_TRANSACTION_HISTORY,
} from "../../utils/constants";
import CustomButton from "../../components/Buttons/CustomButton";
import { BackButton } from "../../components/Buttons/BackButton";
import Pagination from "../../components/Pagination";
import { RowCardDetailHistory } from "../../components/RowCardDetail";
import RowCard from "../../components/RowCard";
import UserDetailHeader from "../../components/Cards/UserDetailHeader";

const UserWallet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state;

  const [pageSize, setPageSize] = useState(9); // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [selectedTransaction, setSelectedTransaction] = useState(null); // Track selected transaction

  const totalPages = Math.ceil(USER_TRANSACTION_HISTORY.length / pageSize);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentTransactions = USER_TRANSACTION_HISTORY.slice(
    startIndex,
    endIndex
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle Previous Page
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleTransactionClick = (transaction: any) => {
    // If the clicked transaction is already selected, deselect it
    if (selectedTransaction === transaction) {
      setSelectedTransaction(null);
    } else {
      setSelectedTransaction(transaction); // Select the clicked transaction
    }
  };

  return (
    <AdminLayout maxW="1129px">
      <Stack align={"flex-start"} maxW={"1049px"} gap={"23px"}>
        <CustomText text={"User Wallets"} size="16px" w="600" />
        <Box
          p={"34px 0px 46px 36px"}
          mt={"23px"}
          borderRadius={"5px"}
          bg={"light.bg"}
          w={"100%"}
        >
          <UserDetailHeader
            date={userData?.date}
            email={userData?.email}
            id={"pay-60001987"}
          />
          <Flex gap={"73px"} align={"flex-start"} mt={"24px"}>
            <Stack align={"flex-start"} gap={"22px"}>
              <Image src={UserImg} alt="user-pic" w={"240px"} h={"154px"} />
              <SummaryBox
                iconTitle={AccountPic}
                title="Account Number"
                descW="500"
                descSize="14px"
                desc={`1234567890`}
              />
              <SummaryBox
                iconTitle={NGNFlag}
                title="NGN Wallet"
                descW="500"
                descSize="14px"
                desc={`Balance: ₦ 981,183.76`}
              />
            </Stack>
            <Stack align={"flex-start"} gap={"22px"}>
              {USER_CRYPTO_DATA1?.map((cryptoData) => (
                <CryptoCard
                  cryptoIcon={cryptoData?.icon}
                  crypto={cryptoData?.name}
                  address={cryptoData?.address}
                  amount={cryptoData?.balance}
                />
              ))}
              <CustomButton
                text="Download Transaction History"
                bgColor="light.bg"
              />
            </Stack>
            <Stack align={"flex-start"} gap={"22px"}>
              {USER_CRYPTO_DATA2?.map((cryptoData) => (
                <CryptoCard
                  cryptoIcon={cryptoData?.icon}
                  crypto={cryptoData?.name}
                  address={cryptoData?.address}
                  amount={cryptoData?.balance}
                />
              ))}
              <BackButton
                title="Exit to main user directory"
                onClick={() => navigate("/user-summary")}
              />
            </Stack>
          </Flex>
        </Box>
        <Stack gap={"10px"} align={"flex-start"} w={"100%"}>
          <Box
            w={"100%"}
            pl={"32px"}
            py={"12px"}
            borderRadius={"5px"}
            bg={"light.bg"}
            boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.02)"}
          >
            <CustomText text="Transaction History" size="15px" w="500" />
          </Box>
          {currentTransactions.map((trx: any) => (
            <>
              {selectedTransaction === trx ? (
                <RowCardDetailHistory
                  date={trx.date}
                  description={trx.description}
                  amount={trx.amount}
                  reference={trx.reference}
                  recipientAccount={trx.recipientAccount}
                  currentBal={trx.currentBalance}
                  onClick={() => setSelectedTransaction(null)}
                />
              ) : (
                <RowCard
                  date={trx.date}
                  text={trx.description}
                  amount={trx.amount}
                  onClick={() => handleTransactionClick(trx)}
                />
              )}
            </>
          ))}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageSizeChange={(newSize: any) => {
              setPageSize(newSize);
              setCurrentPage(1); // Reset to page 1 when page size changes
            }}
            onPreviousPage={handlePreviousPage}
            onNextPage={handleNextPage}
            leftArrowIcon={LeftArrow}
            rightArrowIcon={RightArrow}
          />
        </Stack>
      </Stack>
    </AdminLayout>
  );
};

export default UserWallet;

const CryptoCard = ({ cryptoIcon, crypto, address, amount }: any) => {
  return (
    <Stack
      w={"240px"}
      h={"66px"}
      gap={"7.43px"}
      justify={"center"}
      pl={"9px"}
      borderRadius={"5px"}
      border={"1px solid"}
      borderColor={"light.border.secondary"}
    >
      <Flex justify={"space-between"} align={"flex-start"} w={"216px"}>
        <Flex align={"center"} gap={"5px"}>
          <Image src={cryptoIcon} alt="crypto" w={"22px"} />
          <CustomText text={crypto} size="13px" w="600" lh="17px" />
        </Flex>
        <Flex align={"center"} gap={"5px"}>
          <CustomText text={address} size="14px" w="500" />
          <Image src={CopyIcon} alt="copyIcon" />
        </Flex>
      </Flex>
      <CustomText text={`Balance: ${amount}`} size="14px" w="500" />
    </Stack>
  );
};
