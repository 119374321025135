import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import bgImage from "../assets/auth/BackgroundImage.png";
import AuthNav from "../components/Auth/AuthNav";
import AuthCard from "../components/Auth/AuthCard";

const AuthLayout = ({ children, noAuthNav, bg }: any) => {
  const bgColor = useColorModeValue("colors.light.bg", "#030303");
  return (
    <Box
      bgImage={bgImage}
      bgColor={bgColor}
      minH="100vh"
      display="flex"
      flexDirection="column"
      pb="50px"
      fontSize={"14px"}
    >
      {noAuthNav ? <Box h="100px" /> : <AuthNav />}
      <Flex justify={"center"} align={"center"} direction={"column"} flex={1}>
        <AuthCard bgColor={bg}>{children}</AuthCard>
      </Flex>
    </Box>
  );
};

export default AuthLayout;
