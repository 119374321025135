import { Flex, Image, Stack, Textarea } from "@chakra-ui/react";
import React from "react";
import CustomText from "../Text/CustomText";
import SaveBtn from "../../assets/save-icon.svg";

const RemarksCard = ({ icon, fromHist, text1, text2, text3, text4 }: any) => {
  return (
    <Stack w={"100%"}>
      <Flex
        p={"5px 6px"}
        maxW={"649px"}
        borderRadius={"5px"}
        bg={"light.bgGray"}
        mb={"-36px"}
        ml={"-10px"}
        position={"relative"}
      >
        <CustomText text={"Remarks- "} size="14px" w="600" lh="17px" />
        <CustomText
          text={
            "Source of funds, relationship between user and source of funds, funds supporting document and  sanctions screening remarks (required)."
          }
          size="13px"
          w="400"
          lh="17px"
        />
      </Flex>
      <Flex
        p={"32px 12px 30px 9px"}
        borderRadius={"5px"}
        maxW={{ base: "90%", xl: "906px" }}
        border={"1px solid"}
        borderColor={"light.border.secondary"}
        bg={"light.bg"}
        flexDirection={"column"}
        onClick={(e) => e.stopPropagation()}
      >
        {fromHist ? (
          <>
            <CustomText text={text1} />
            <CustomText text={text2} />
            <CustomText text={text3} />
            <CustomText text={text4} />
          </>
        ) : (
          <>
            <Textarea
              placeholder="I..."
              // value={remarks}
              // onChange={(e) => setRemarks(e.target.value)}
              size="md"
              h="100px"
              mb="3"
              resize="none"
              borderColor="gray.300"
              _focus={{ borderColor: "blue.500" }}
            />

            {/* Save Button */}
            <Flex
              justify="flex-end"
              align="center"
              gap={"10px"}
              mr={"12px"}
              mt={"-60px"}
              position={"relative"}
              zIndex={5}
            >
              <Image src={SaveBtn} alt="" />
              <Image src={icon} alt="" />
            </Flex>
          </>
        )}
      </Flex>
    </Stack>
  );
};

export default RemarksCard;
