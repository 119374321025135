import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import PrimaryText from "./Text/PrimaryText";
import TextWithEmail from "./Text/TextWithEmail";
import CustomText from "./Text/CustomText";

const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

const RowCard = ({ bgCard, date, text, amount, onClick, fromHome }: any) => {
  const emails = text.match(emailRegex); // Find emails in description

  let text1 = text;
  let text2 = "";
  let text3 = "";

  if (emails && emails.length > 0) {
    // Split the text based on the first email
    const [beforeEmail, afterEmail] = text.split(emails[0]);
    text1 = beforeEmail.trim(); // Text before the first email (trim to remove extra spaces)
    text2 = emails[0]; // First email

    // Remove the "to" from the text after the first email
    const cleanedAfterEmail = afterEmail.replace(/\bto\b\s*/, "").trim();

    // Check if there's a second email
    const secondEmailMatch = cleanedAfterEmail.match(emailRegex);
    if (secondEmailMatch) {
      const [afterFirstEmail] = cleanedAfterEmail.split(secondEmailMatch[0]);
      text3 = secondEmailMatch[0]; // Second email
      text1 += afterFirstEmail.trim(); // Continue first part of the text after the first email
    } else {
      text1 += cleanedAfterEmail; // No second email, just append remaining text to text1
    }
  }

  const isDeposit = text.toLowerCase().includes("deposit");

  return (
    <Flex
      align={"center"}
      // gap={"94px"}
      w={"100%"}
      justify={"space-between"}
      _hover={{ bg: "light.primary" }}
      p={{ base: "8px 8px 19px 20px", md: "16px 70px 19px 48px" }}
      h={"75px"}
      borderRadius={"0px 5px 5px 5px"}
      bg={bgCard || "light.bg"}
      maxW={"1009px"}
      minW={"750px"}
      cursor={"pointer"}
      onClick={onClick}
    >
      <PrimaryText text={date?.split(",")[0]} />
      <TextWithEmail
        text1={text1} // Part before the first email
        text2={text2} // First email
        text3={text3} // Second email (if exists)
        size={{ base: "12px", md: "17px" }}
        color={!fromHome ? "#000" : isDeposit ? "light.text.secondary" : "#F00"} // Apply conditional color
        w={"536px"}
      />
      <Box w={{ md: "159px" }} textAlign={"right"}>
        <CustomText text={amount} size={{ base: "12px", md: "17px" }} w="500" />
      </Box>
    </Flex>
  );
};

export default RowCard;
