import { Center, Flex, Image, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import PrimaryText from "./Text/PrimaryText";
import CustomText from "./Text/CustomText";
import ThemeToggler from "../assets/theme-toggler.svg";

const Header = ({ text, icon, textColor, maxW }: any) => {
  const [visible, setVisible] = useState(false);

  // Effect to handle visibility based on `text` or `icon` change
  useEffect(() => {
    setVisible(true); // Show the component when text or icon changes

    const timeout = setTimeout(() => {
      setVisible(false); // Hide the component after 3 seconds
    }, 3000);

    return () => clearTimeout(timeout); // Clear timeout on cleanup
  }, [text, icon]); // Re-trigger when `text` or `icon` changes

  return (
    <Flex
      align={{ md: "center" }}
      justify={"space-between"}
      maxW={{ base: "100%", md: maxW ? maxW : "989px" }}
      mb={{ base: "36px", md: "51px" }}
      ml={{ base: "8px", md: "20px" }}
      gap={"6px"}
      flexDirection={{ base: "column-reverse", md: "row" }}
    >
      <Stack align={"flex-start"} gap={{ md: "6px" }}>
        <PrimaryText text="Good Morning" w="500" />
        <CustomText
          text="Olorunfemi"
          size={{ base: "16px", md: "24px" }}
          w="700"
          lh={{ md: "25px" }}
        />
      </Stack>
      {icon && visible && (
        <Center
          w={"251px"}
          h={{ base: "40px", md: "57px" }}
          borderRadius={"20px"}
          border={"1px solid"}
          borderColor={"light.border.secondary"}
          bg={"rgba(255,255,255,0.7)"}
          gap={{ base: "7px", md: "14px" }}
        >
          <Image src={icon} alt="icon" />
          <CustomText text={text} color={textColor} w="500" lh="14px" />
        </Center>
      )}
      <Image
        src={ThemeToggler}
        alt="theme toggler"
        w={{ base: "100px", md: "213px" }}
      />
    </Flex>
  );
};

export default Header;
