import React, { useState } from "react";
import AuthLayout from "../../layout/AuthLayout";
import AuthCardHeader from "../../components/Auth/AuthCardHeader";
import UserInfoInput from "../../components/Auth/UserInfoInput";
import emailLight from "../../assets/auth/emailIcon.svg";
import emailLightActive from "../../assets/auth/emailActive.svg";
import lockLight from "../../assets/auth/lockIcon.svg";
import lockActive from "../../assets/auth/lockActive.svg";
import eyeLight from "../../assets/auth/eyeIcon.svg";
import eyeActive from "../../assets/auth/eyeActive.svg";
import shutEyeLight from "../../assets/auth/shutEyeIcon.svg";
import shutEyeLightActive from "../../assets/auth/shutEyeActive.svg";
import emailDark from "../../assets/auth/email.svg";
import lockDark from "../../assets/auth/lock.svg";
import eyeDark from "../../assets/auth/eye.svg";
import shutEyeDark from "../../assets/auth/shutEye.svg";
import { Link as ChakraLink, Text } from "@chakra-ui/react";
import { Box, useColorModeValue } from "@chakra-ui/react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

const Login = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const emailIcon = useColorModeValue(emailLight, emailDark);
  const lockIcon = useColorModeValue(lockLight, lockDark);
  const eyeIcon = useColorModeValue(eyeLight, eyeDark);
  const shutEyeIcon = useColorModeValue(shutEyeLight, shutEyeDark);
  const activeEyeIcon = useColorModeValue(eyeActive, eyeDark);
  const activeShutEyeIcon = useColorModeValue(shutEyeLightActive, shutEyeDark);
  const paraColor2 = useColorModeValue("#909090", "#C7C7C7");

  return (
    <AuthLayout>
      <AuthCardHeader
        title="Sign in"
        des="Login to resume your journey with Payremit."
        desColor={paraColor2}
      />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          navigate("/");
        }}
      >
        <UserInfoInput
          type="email"
          placeholder="Email"
          darkBG="transparent"
          icon={emailIcon}
          activeIcon={useColorModeValue(emailLightActive, emailDark)}
          name="email"
          value={email}
          onChange={(e: any) => handleChange(e)}
          border={!emailPattern.test(email) && email && "red"}
        />
        {/* {!emailPattern.test(email) && email && ( */}
        {/* <Text mt="-16px" color={"red"}> */}
        {/* Enter correct email{" "} */}
        {/* </Text> */}
        {/* )} */}
        <UserInfoInput
          type={show ? "text" : "password"}
          placeholder="Password"
          darkBG="transparent"
          icon={lockIcon}
          activeIcon={useColorModeValue(lockActive, lockDark)}
          rightIcon={!show ? eyeIcon : shutEyeIcon}
          activeRightIcon={!show ? activeEyeIcon : activeShutEyeIcon}
          setShow={setShow}
          show={show}
          name="password"
          value={password}
          onChange={(e: any) => handleChange(e)}
          // border={loginError && "red"}
        />
        {/* {loginError && (
          <Text mt="-16px" color={"red"}>
            {loginError}. Try again{" "}
          </Text>
        )} */}
        <Box textAlign={"right"} mt="-16px">
          <ChakraLink
            as={ReactRouterLink}
            to="/forgot-password"
            color={useColorModeValue("#00332D", "#00FFE0")}
            fontWeight={600}
            fontSize={"12px"}
          >
            Forgot Password?
          </ChakraLink>
        </Box>
        <PrimaryButton
          mt="130px"
          type="submit"
          text="Login"
          // text={loginMutation.isLoading ? <Spinner /> : "Login"}
          // disabled={loginMutation.isLoading}
        />
      </form>
      <Text textAlign={"center"} mt="20px" fontSize={"12px"}>
        Not an Admin?{" "}
        <ChakraLink
          as={ReactRouterLink}
          to="https://payremit-web-app.vercel.app/"
          color={"#00FFE0"}
          fontWeight={600}
          fontSize={"12px"}
          ml="8px"
        >
          Sign in here
        </ChakraLink>
      </Text>
    </AuthLayout>
  );
};

export default Login;
