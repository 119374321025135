import React, { useState } from "react";
import AdminLayout from "../../layout";
import { Box, Flex, Image, Stack } from "@chakra-ui/react";
import CustomText from "../../components/Text/CustomText";
import { SummaryBox } from "../../components/Cards/SummaryBox";
import { useLocation, useNavigate } from "react-router-dom";
import { USER_REFERRAL_ACTIVITY } from "../../utils/constants";
import UserImg from "../../assets/user-pic.png";
import LeftArrow from "../../assets/left-arrow.svg";
import EditIcon from "../../assets/edit-icon.svg";
import RightArrow from "../../assets/right-arrow.svg";
import { BackButton } from "../../components/Buttons/BackButton";
import Pagination from "../../components/Pagination";
import CustomButton from "../../components/Buttons/CustomButton";
import UserDetailHeader from "../../components/Cards/UserDetailHeader";

const UserReferral = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state;

  const [pageSize, setPageSize] = useState(9); // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page

  const totalPages = Math.ceil(USER_REFERRAL_ACTIVITY.length / pageSize);

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentTransactions = USER_REFERRAL_ACTIVITY.slice(
    startIndex,
    endIndex
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle Previous Page
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <AdminLayout>
      <Stack align={"flex-start"} maxW={"1049px"} gap={"23px"}>
        <CustomText text={"User Referral Settings"} size="16px" w="600" />
        <Box
          p={"34px 0px 46px 36px"}
          mt={"23px"}
          borderRadius={"5px"}
          bg={"light.bg"}
          w={"100%"}
        >
          <UserDetailHeader
            date={userData?.date}
            email={userData?.email}
            id={"pay-60001987"}
          />
          <Flex gap={"73px"} align={"flex-start"} mt={"24px"}>
            <Stack align={"flex-start"} gap={"22px"}>
              <Image src={UserImg} alt="user-pic" w={"240px"} h={"154px"} />
              <SummaryBox
                title="Charge per referral"
                desc={`₦150`}
                icon2={EditIcon}
              />
              <BackButton
                title="Exit to main user directory"
                onClick={() => navigate("/users")}
              />
            </Stack>
            <Stack align={"flex-start"} gap={"22px"}>
              <SummaryBox
                title="Referral Code"
                desc={`DAMXTON`}
                icon={true}
                icon2={EditIcon}
              />
              <SummaryBox title="Total Referral Earnings" desc={`₦10,000`} />
              <SummaryBox title="Current Referral Earnings" desc={`₦5,000`} />

              <CustomButton
                text="Download Referral Activity"
                bgColor="light.bg"
              />
            </Stack>
            <Stack align={"flex-start"} gap={"22px"}>
              <SummaryBox title="Users referred" desc={`10`} />

              <BackButton
                title="Exit to user summary page"
                onClick={() => navigate("/user-summary")}
                nextP={true}
              />
            </Stack>
          </Flex>
        </Box>
        <Stack gap={"10px"} align={"flex-start"} w={"100%"}>
          <Box
            w={"100%"}
            pl={"32px"}
            py={"12px"}
            borderRadius={"5px"}
            bg={"light.bg"}
            boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.02)"}
          >
            <CustomText text="Referral Activity" size="15px" w="500" />
            {currentTransactions?.map((activity) => (
              <Flex
                align={"center"}
                gap={"120px"}
                h={"44px"}
                //   justify={"center"}
                alignItems={"center"}
                my={"5px"}
              >
                <Box w={"142px"}>
                  <CustomText text={activity?.date} size="13px" lh="18px" />
                </Box>
                <Box w={"274px"}>
                  <CustomText text={activity?.name} size="13px" lh="18px" />
                </Box>
                <Box w={"254px"}>
                  <CustomText text={activity?.email} size="13px" lh="18px" />
                </Box>
              </Flex>
            ))}
          </Box>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageSizeChange={(newSize: any) => {
              setPageSize(newSize);
              setCurrentPage(1); // Reset to page 1 when page size changes
            }}
            onPreviousPage={handlePreviousPage}
            onNextPage={handleNextPage}
            leftArrowIcon={LeftArrow}
            rightArrowIcon={RightArrow}
          />
        </Stack>
      </Stack>
    </AdminLayout>
  );
};

export default UserReferral;
