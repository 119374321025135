import React, { useState } from "react";
import { Box, Image, Text, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import emailLight from "../../assets/auth/emailIcon.svg";
import emailLightActive from "../../assets/auth/emailActive.svg";
import emailDark from "../../assets/auth/email.svg";
import arrow1 from "../../assets/auth/backArrow.svg";
import arrow2 from "../../assets/auth/backArrowDark.svg";
import AuthLayout from "../../layout/AuthLayout";
import AuthCardHeader from "../../components/Auth/AuthCardHeader";
import UserInfoInput from "../../components/Auth/UserInfoInput";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const { email } = formData;
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [, setIsInvalid] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const arrowIcon = useColorModeValue(arrow1, arrow2);
  const emailIcon = useColorModeValue(emailLight, emailDark);

  return (
    <AuthLayout>
      <Image
        src={arrowIcon}
        alt="arrow"
        onClick={() => navigate("/signin")}
        cursor={"pointer"}
        mb={"40px"}
      />
      <AuthCardHeader
        title="Forgot Password?"
        des="Enter your associated email to get the verification code."
        desColor={useColorModeValue("#909090", "#C7C7C7")}
      />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          navigate("/verify-email", {
            state: { formData: formData, from: "forgotPassword" },
          });
        }}
      >
        <UserInfoInput
          type="email"
          placeholder="Email"
          darkBG="transparent"
          icon={emailIcon}
          activeIcon={useColorModeValue(emailLightActive, emailDark)}
          name="email"
          value={email}
          onChange={(e: any) => handleChange(e)}
          border={!emailPattern.test(email) && email && "red"}
        />
        {!emailPattern.test(email) && email && (
          <Text mt="-16px" color={"red"}>
            Enter correct email{" "}
          </Text>
        )}
        <Box minH="200px"></Box>
        <PrimaryButton
          type="submit"
          text={"Get Verification Code"}
          onClick={() => {
            if (email === "") {
              setIsInvalid(true);
            } else setIsInvalid(false);
          }}
        />
      </form>
    </AuthLayout>
  );
};

export default ForgotPassword;
