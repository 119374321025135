import { Flex, Select, Image, Stack, Center } from "@chakra-ui/react";
import { useState } from "react";
import LeftArrow from "../../assets/left-arrow.svg";
import RightArrow from "../../assets/right-arrow.svg";
import AdminLayout from "../../layout";
import PrimaryText from "../../components/Text/PrimaryText";
import SearchHeader from "../../components/SearchHeader";
import RowCard from "../../components/RowCard";
import { TRANSACTIONS } from "../../utils/constants";
import BulbIcon from "../../assets/bulb.svg";
import { RowCardDetail } from "../../components/RowCardDetail";
import TickIcon from "../../assets/tick-icon.svg";
import CloseIcon from "../../assets/close-icon.svg";

const Home = () => {
  const [pageSize, setPageSize] = useState(9); // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [selectedTransaction, setSelectedTransaction] = useState(null); // Track selected transaction
  const [toastContent, setToastContent] = useState({
    icon: BulbIcon,
    text: "Remainder: Enable your 2FA",
    textColor: "light.remainder",
  });
  // Total number of pages
  const totalPages = Math.ceil(TRANSACTIONS.length / pageSize);

  // Calculate the start and end indices of the transactions to show for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentTransactions = TRANSACTIONS.slice(startIndex, endIndex);

  // Handle Next Page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle Previous Page
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleTransactionClick = (transaction: any) => {
    // If the clicked transaction is already selected, deselect it
    if (selectedTransaction === transaction) {
      setSelectedTransaction(null);
    } else {
      setSelectedTransaction(transaction); // Select the clicked transaction
    }
  };

  return (
    <AdminLayout
      icon={toastContent.icon}
      text={toastContent.text}
      textColor={toastContent.textColor}
    >
      <SearchHeader
        title="Pending transactions"
        searchPlaceholder="Click the settings to toggle search options"
        filterItems={["Currency Type"]}
        hoverMenuItems={{
          "Currency Type": ["NGN", "USD", "BTC", "ETH", "TRX"],
        }}
      />
      <Stack align={"flex-start"} gap={"11px"} overflowX={"scroll"}>
        {currentTransactions.map((trx: any) => (
          <>
            {selectedTransaction === trx ? (
              <RowCardDetail
                date={trx.date}
                description={trx.description}
                amount={trx.amount}
                reference={trx.reference}
                recipientAccount={trx.recipientAccount}
                currentBal={trx.currentBalance}
                onClick={() => setSelectedTransaction(null)}
                onApproveClick={() => {
                  setToastContent({
                    icon: TickIcon,
                    text: "Transaction Approved!",
                    textColor: "light.text.secondary",
                  });
                }}
                onRejectClick={() => {
                  setToastContent({
                    icon: CloseIcon,
                    text: "Transaction Rejected!",
                    textColor: "#FF000D",
                  });
                }}
              />
            ) : (
              <RowCard
                date={trx.date}
                text={trx.description}
                amount={trx.amount}
                fromHome={true}
                onClick={() => handleTransactionClick(trx)}
              />
            )}
          </>
        ))}
        <Flex
          align={"center"}
          justify={"space-between"}
          w={"100%"}
          maxW={"1009px"}
        >
          <Flex align={"center"} gap={"10px"}>
            <PrimaryText text="Showing" />
            <Select
              variant={"primary"}
              w="70px"
              h="44px"
              borderRadius={"5px"}
              bg={"light.bg"}
              boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.2)"}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setCurrentPage(1); // Reset to page 1 when page size changes
              }}
              value={pageSize}
              textColor={"light.text.primary"}
              fontSize={"14px"}
            >
              {[9, 10, 50, 500, 1000, 5000].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex align={"center"} gap={"20px"}>
            {/* Previous Button */}
            <Center
              w={"32px"}
              h={"44px"}
              borderRadius={"5px"}
              bg={"light.bg"}
              boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.02)"}
              cursor={currentPage === 1 ? "not-allowed" : "pointer"}
              onClick={handlePreviousPage}
            >
              <Image
                src={LeftArrow}
                alt="previous page"
                opacity={currentPage === 1 ? 0.5 : 1}
              />
            </Center>

            {/* Next Button */}
            <Center
              w={"32px"}
              h={"44px"}
              borderRadius={"5px"}
              bg={"light.bg"}
              boxShadow={"0px 4px 40px 0px rgba(0,0,0,0.02)"}
              cursor={currentPage === totalPages ? "not-allowed" : "pointer"}
              onClick={handleNextPage}
            >
              <Image
                src={RightArrow}
                alt="next page"
                opacity={currentPage === totalPages ? 0.5 : 1}
              />
            </Center>
          </Flex>
        </Flex>
      </Stack>
    </AdminLayout>
  );
};

export default Home;
