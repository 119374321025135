import { Box, useColorModeValue } from "@chakra-ui/react";
import customTheme from "../../theme";

const AuthCard = ({ children, bgColor }: any) => {
  const borderColor = useColorModeValue(
    customTheme.colors.light.border.secondary,
    customTheme.colors.dark.border.secondary
  );
  const bg = useColorModeValue(
    "rgba(255, 255, 255, 0.10)",
    "rgba(18, 27, 24, 0.30)"
  );

  return (
    <Box
      maxW="510px"
      mx="auto"
      w="90%"
      border="3px solid"
      borderRadius={"24px"}
      borderColor={borderColor}
      p={["40px 10px", "40px 20px", "40px"]}
      bg={bgColor || bg}
    >
      {children}
    </Box>
  );
};

export default AuthCard;
