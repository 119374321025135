import { Box, Flex, Stack } from "@chakra-ui/react";
import React from "react";
import CopyIcon from "../assets/copy-icon.svg";
import CustomButton from "./Buttons/CustomButton";
import DetailCard from "./Cards/DetailCard";
import DetailCardLg from "./Cards/DetailCardLg";
import RemarksCard from "./Cards/RemarksCard";

export const RowCardDetail = ({
  date,
  description,
  amount,
  onClick,
  reference,
  recipientAccount,
  currentBal,
  onApproveClick,
  onRejectClick,
}: any) => {
  const isNGN = description.toLowerCase().includes("ngn");
  const isDeposit = description.toLowerCase().includes("deposit");
  const isTwoEmails = description.toLowerCase().startsWith("withdrawal");
  return (
    <Box
      onClick={onClick}
      w={"100%"}
      maxW={"1009px"}
      minW={"750px"}
      borderRadius={"0px 5px 5px 5px"}
      bg={"#FFF"}
      cursor={"pointer"}
    >
      <Stack
        m={{ base: "21px 6px 12px 30px", md: "42px 12px 24px 30px" }}
        gap={"34px"}
        w={"100%"}
      >
        <Flex
          align={"center"}
          // justify={"space-between"}
          gap={"39px"}
          maxW={"929px"}
          alignItems={"flex-start"}
          flexWrap={"wrap"}
        >
          <DetailCard title="Date" desc={date} />
          <DetailCard
            title="Transaction Details"
            desc={description}
            descColor="light.redColor"
            width={{ base: "240px", md: "332px" }}
            textG={true}
            icon={CopyIcon}
            fromHome={true}
          />
          <DetailCard
            title="Amount"
            desc={amount}
            descSize={{ base: "13px", md: "17px" }}
            descW="500"
          />
        </Flex>
        <Flex
          gap={{ base: "10px", xl: "127px" }}
          align={"flex-start"}
          w={"100%"}
          flexWrap={"wrap"}
        >
          <Flex gap={{ base: "22px", md: "59px" }} align={"flex-start"}>
            <Stack gap={{ base: "14px", md: "29px" }} align={"flex-start"}>
              {isNGN && !isTwoEmails && (
                <DetailCard
                  title="Transaction Reference"
                  desc={reference}
                  icon={CopyIcon}
                />
              )}
              {!isNGN && !isTwoEmails && (
                <>
                  <DetailCard
                    title="Sender Wallet"
                    desc={"1Lbcfr7s****4ZnX71"}
                    icon={CopyIcon}
                  />
                  <DetailCard
                    title="Recipient Wallet"
                    desc={"1Lbcfr7s****4ZnX71"}
                    icon={CopyIcon}
                  />
                </>
              )}
              {isTwoEmails && (
                <>
                  <DetailCard
                    title="Sender ID"
                    desc={"pay-60001987"}
                    icon={CopyIcon}
                  />
                  <DetailCard
                    title="Receiver ID"
                    desc={"pay-60001987"}
                    icon={CopyIcon}
                  />
                </>
              )}
            </Stack>
            <Stack gap={{ base: "11px", md: "22px" }}>
              {isNGN && !isTwoEmails && (
                <DetailCardLg
                  title="Recipient account"
                  desc1={recipientAccount?.bank}
                  desc2={recipientAccount?.accountNumber}
                  desc3={recipientAccount?.accountName}
                  icon={CopyIcon}
                />
              )}
              {isTwoEmails && (
                <DetailCard
                  title="Transaction Reference"
                  desc={reference}
                  icon={CopyIcon}
                />
              )}
              <DetailCard
                title="Current balance (NGN)"
                desc={currentBal}
                descW="500"
              />
            </Stack>
          </Flex>
          <Stack gap={"13px"} justify={"center"} align={"center"}>
            <CustomButton
              text="Approve"
              w={{ base: "180px", md: "240px" }}
              br="5px"
              bgColor="light.primary"
              onClick={(e: any) => {
                e.stopPropagation();
                onApproveClick();
              }}
            />
            <CustomButton
              text="Reject"
              w={{ base: "180px", md: "240px" }}
              br="5px"
              bgColor="light.bg"
              onClick={(e: any) => {
                e.stopPropagation();
                onRejectClick();
              }}
              //   borderC="red"
            />
          </Stack>
        </Flex>
        {isNGN && isDeposit && (
          <RemarksCard
            // title="Amount"
            // desc={amount}
            // descSize="17px"
            // descW="500"
            icon={CopyIcon}
          />
        )}
      </Stack>
    </Box>
  );
};

export const RowCardDetailHistory = ({
  date,
  description,
  amount,
  onClick,
  reference,
  recipientAccount,
  currentBal,
  onApproveClick,
  onRejectClick,
}: any) => {
  const isNGN = description.toLowerCase().includes("ngn");
  const isDeposit = description.toLowerCase().includes("deposit");
  // const isTwoEmails = description.toLowerCase().startsWith("withdrawal");
  const isVendor = description.toLowerCase().includes("utilities");
  const prepaid = description.toLowerCase().includes("prepaid");
  const dstv = description.toLowerCase().includes("dstv");
  const data = description.toLowerCase().includes("data");
  const mtn = description.toLowerCase().includes("mtn");
  return (
    <Box
      onClick={onClick}
      w={"100%"}
      minW={"750px"}
      maxW={"1009px"}
      borderRadius={"0px 5px 5px 5px"}
      bg={"#FFF"}
      cursor={"pointer"}
    >
      <Stack
        m={{ base: "21px 6px 12px 30px", md: "42px 12px 24px 30px" }}
        gap={"34px"}
        w={"100%"}
      >
        <Flex
          align={"center"}
          // justify={"space-between"}
          gap={"39px"}
          maxW={"929px"}
          alignItems={"flex-start"}
          flexWrap={"wrap"}
        >
          <DetailCard title="Date of Transaction" desc={date} />
          <DetailCard
            title={isVendor ? "Vendor" : "Transaction Details"}
            desc={description}
            descColor=""
            width={{ base: "240px", md: "332px" }}
            textG={true}
            icon={CopyIcon}
          />
          <DetailCard
            title="Amount"
            desc={amount}
            descSize={{ base: "13px", md: "17px" }}
            descW="500"
          />
        </Flex>
        <Flex
          gap={{ base: "10px", xl: "127px" }}
          align={"flex-start"}
          w={"100%"}
          flexWrap={"wrap"}
        >
          <Flex gap={{ base: "22px", md: "59px" }} align={"flex-start"}>
            <Stack gap={{ base: "14px", md: "29px" }} align={"flex-start"}>
              {isNGN ? (
                <DetailCard
                  title="Account Name"
                  desc={"John Doe"}
                  icon={CopyIcon}
                />
              ) : prepaid ? (
                <DetailCard
                  title="Meter Number"
                  desc={"54300003834"}
                  icon={CopyIcon}
                />
              ) : dstv ? (
                <DetailCard
                  title="Smartcard Number"
                  desc={"1062173669"}
                  icon={CopyIcon}
                />
              ) : data ? (
                <DetailCard
                  title="Subscription plan"
                  desc={"MTN Ruby"}
                  icon={CopyIcon}
                />
              ) : mtn ? (
                <DetailCard
                  title="Description"
                  desc={"Airtime"}
                  icon={CopyIcon}
                />
              ) : (
                <DetailCard
                  title="Sender wallet"
                  desc={"1Lbcfr7s****4ZnX71"}
                  icon={CopyIcon}
                />
              )}
              {isNGN ? (
                <DetailCard
                  title="Transaction Reference"
                  desc={reference}
                  icon={CopyIcon}
                />
              ) : prepaid ? (
                <DetailCard
                  title="Token"
                  desc={"29781384915919877681"}
                  icon={CopyIcon}
                />
              ) : dstv ? (
                <DetailCard
                  title="Subscription plan"
                  desc={"DSTV Asian Add-on"}
                  icon={CopyIcon}
                />
              ) : data ? (
                <DetailCard
                  title="Subscription details"
                  desc={"300GB"}
                  icon={CopyIcon}
                />
              ) : mtn ? (
                <DetailCard
                  title="Airtime amount"
                  desc={"30,000"}
                  icon={CopyIcon}
                />
              ) : (
                <DetailCard
                  title="Recipient wallet"
                  desc={"1Lbcfr7s****4ZnX71"}
                  icon={CopyIcon}
                />
              )}
            </Stack>
            <Stack gap={{ base: "11px", md: "22px" }}>
              {isNGN && (
                <DetailCardLg
                  title="Recipient account"
                  desc1={recipientAccount?.bank}
                  desc2={recipientAccount?.accountNumber}
                  desc3={recipientAccount?.accountName}
                  icon={CopyIcon}
                />
              )}
              {isVendor ? (
                <DetailCard
                  title="Phone number"
                  desc={"09035002147"}
                  descW="500"
                />
              ) : (
                <DetailCard
                  title="Current balance (NGN)"
                  desc={currentBal}
                  descW="500"
                />
              )}

              {isVendor ? (
                <DetailCard title="Order ID" desc={"4862908"} descW="500" />
              ) : (
                <>
                  {!isNGN && (
                    <DetailCard
                      title="Transaction Hash"
                      desc={"669b77d034621bd212064a8d"}
                      descW="500"
                    />
                  )}
                </>
              )}
            </Stack>
          </Flex>
          <Stack gap={"38px"} justify={"center"} align={"center"}>
            <DetailCard
              title="Approval details"
              desc={isNGN ? "Someone Example" : "Automatic"}
              desc2={isNGN && date}
            />
            <DetailCard
              title="Status"
              desc={"Completed"}
              descW="500"
              descColor="#67C475"
            />
          </Stack>
        </Flex>
        {isNGN && isDeposit && (
          <RemarksCard
            fromHist={true}
            text1="Source of funds: Electronic sales"
            text2="Relationship between user and source of funds: Client"
            text3="Supporting document: Receipt"
            text4="Sanction screening remarks: Flagged"
            // title="Amount"
            // desc={amount}
            // descSize="17px"
            // descW="500"
            icon={CopyIcon}
          />
        )}
      </Stack>
    </Box>
  );
};
