import { Box, Flex, Image } from "@chakra-ui/react";
import CustomText from "../Text/CustomText";
import LeftArrow from "../../assets/left-arrow.svg";
import RightArrow from "../../assets/right-arrow.svg";

export const BackButton = ({ onClick, title, nextP, desc }: any) => {
  return (
    <Flex
      w={"240px"}
      h={"66px"}
      gap={"5px"}
      align={"center"}
      justify={nextP && "center"}
      pl={"18px"}
      _hover={{ bg: "light.primary" }}
      borderRadius={"5px"}
      border={"1px solid"}
      cursor={"pointer"}
      onClick={onClick}
      borderColor={"light.border.secondary"}
    >
      {!nextP && <Image src={LeftArrow} alt="right-arrow" />}
      <Box w={nextP ? "fit-content" : "164px"}>
        <CustomText text={title} size="13px" w="500" lh="14px" />
        <CustomText text={desc} size="11px" />
      </Box>
      {nextP && <Image src={RightArrow} alt="right-arrow" />}
    </Flex>
  );
};
