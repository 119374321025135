import React from "react";
import AdminLayout from "../../layout";
import { Box, Flex, Stack } from "@chakra-ui/react";
import CustomText from "../../components/Text/CustomText";
import DetailCard from "../../components/Cards/DetailCard";

const ReferralSettings = () => {
  return (
    <AdminLayout>
      <Stack align={"flex-start"} maxW={"1049px"} gap={"23px"} mt={"60px"}>
        <CustomText text={"Referral Settings"} size="16px" w="600" />
        <Box
          p={"34px 0px 220px 36px"}
          borderRadius={"5px"}
          bg={"light.bg"}
          w={"100%"}
        >
          <Flex align={"flex-start"} gap={"69px"}>
            <Stack gap={"23px"} align={"flex-start"}>
              <DetailCard />
              <DetailCard />
            </Stack>
          </Flex>
        </Box>
      </Stack>
    </AdminLayout>
  );
};

export default ReferralSettings;
