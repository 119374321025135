import React from "react";
import AdminLayout from "../../layout";
import CustomText from "../../components/Text/CustomText";

import UserImg from "../../assets/user-pic.png";
import EditImg from "../../assets/edit-icon.svg";
import RightArrow from "../../assets/right-arrow.svg";

import { Box, Flex, Image, Stack } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { SummaryBox } from "../../components/Cards/SummaryBox";
import { BackButton } from "../../components/Buttons/BackButton";
import UserDetailHeader from "../../components/Cards/UserDetailHeader";

const UserSummary = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location.state;
  return (
    <AdminLayout maxW="1129px">
      <CustomText text={"User Summary"} size="16px" w="600" />
      <Box
        p={"34px 0px 46px 36px"}
        mt={"23px"}
        borderRadius={"5px"}
        bg={"light.bg"}
        maxW={"1047px"}
      >
        <UserDetailHeader
          date={userData?.date}
          email={userData?.email}
          id={"pay-60001987"}
        />
        <Flex gap={"69px"} align={"flex-start"} mt={"27px"}>
          <Image src={UserImg} alt="user-img" />
          <Stack align={"flex-start"} gap={"22px"}>
            <SummaryBox
              title="Full Name"
              desc={userData?.name}
              icon={true}
              icon2={EditImg}
            />
            <UserButtons
              title="User Details"
              onClick={() => navigate("/user-kyc", { state: userData })}
            />
            <UserButtons
              title="Wallets"
              desc={`NGN Balance: ₦2,123,000.00`}
              onClick={() => navigate("/user-wallet", { state: userData })}
            />
            <UserButtons
              title="Referral Activity"
              onClick={() => navigate("/user-referral", { state: userData })}
            />
          </Stack>
          <Stack gap={"22px"} align={"flex-start"}>
            <UserButtons
              title="KYC Settings"
              titleW={"500"}
              desc={`Validation Pending`}
              onClick={() => navigate("/kyc-settings", { state: userData })}
            />
            <UserButtons
              title="User Activity"
              desc={`Role: user`}
              onClick={() => navigate("/user-activity", { state: userData })}
            />
            <BackButton
              onClick={() => navigate("/users")}
              title="Exit to main user directory"
            />
          </Stack>
        </Flex>
      </Box>
    </AdminLayout>
  );
};

export default UserSummary;

const UserButtons = ({ title, desc, titleW, onClick }: any) => {
  return (
    <Flex
      w={"240px"}
      h={"66px"}
      gap={"5px"}
      align={"center"}
      pl={"18px"}
      onClick={onClick}
      cursor={"pointer"}
      _hover={{ bg: "light.primary" }}
      borderRadius={"5px"}
      border={"1px solid"}
      borderColor={"light.border.secondary"}
    >
      <Stack gap={"5px"}>
        <CustomText text={title} size="13px" lh="14px" w={titleW || "400"} />
        <Box w={"164px"}>
          <CustomText text={desc} size="11px" />
        </Box>
      </Stack>
      <Image src={RightArrow} alt="right-arrow" />
    </Flex>
  );
};
