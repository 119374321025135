import { Flex, Image, Stack } from "@chakra-ui/react";
import React from "react";
import CustomText from "../Text/CustomText";

const DetailCardLg = ({ title, desc1, desc2, desc3, icon }: any) => {
  return (
    <Stack w={"100%"} width={"240px"}>
      <Flex
        p={"5px 6px"}
        maxW={"191px"}
        borderRadius={"5px"}
        bg={"light.bgGray"}
        mb={"-30px"}
        ml={"-10px"}
        position={"relative"}
      >
        <CustomText text={title} size="13px" w="600" lh="17px" />
      </Flex>
      <Flex
        p={"32px 0px 11px 9px"}
        maxW={"240px"}
        borderRadius={"5px"}
        border={"1px solid"}
        borderColor={"light.border.secondary"}
        bg={"light.bg"}
        flexDirection={"column"}
      >
        <Flex align={"center"} gap={"2px"}>
          <CustomText
            text={"Bank:"}
            color={"text"}
            size="13px"
            lh="18px"
            w="600"
          />
          <CustomText text={desc1} color={"text"} size="13px" lh="18px" />
        </Flex>
        <Flex align={"center"} gap={"10px"}>
          <Flex align={"center"} gap={"2px"}>
            <CustomText
              text={"Account Number:"}
              color={"text"}
              size="13px"
              lh="18px"
              w="600"
            />
            <CustomText text={desc2} color={"text"} size="13px" lh="18px" />
            {icon && <Image src={icon} alt="copyIcon" />}
          </Flex>
        </Flex>
        <Flex align={"center"} gap={"2px"}>
          <CustomText
            text={"AccountName:"}
            color={"text"}
            size="13px"
            lh="18px"
            w="600"
          />
          <CustomText text={desc3} color={"text"} size="13px" lh="18px" />
        </Flex>
      </Flex>
    </Stack>
  );
};

export default DetailCardLg;
