import { Button, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import customTheme from "../../theme";

const PrimaryButton = ({
  text,
  onClick,
  type,
  mt,
  icon,
  bgLight,
  disabled,
  isLoading,
  height,
}: any) => {
  const bg = useColorModeValue(
    bgLight || customTheme.colors.dark.accent.primary,
    customTheme.colors.light.accent.primary
  );
  const color = useColorModeValue(
    customTheme.colors.light.accent.primary,
    customTheme.colors.dark.accent.primary
  );

  const borderColor = useColorModeValue(
    customTheme.colors.light.border.primary,
    customTheme.colors.dark.border.primary
  );

  const bgLightBorderColor = useColorModeValue(
    "transparent",
    customTheme.colors.dark.border.primary
  );

  return (
    <Button
      height={height}
      _hover={{ bg: bg }}
      p="24px 10px"
      w="100%"
      bg={bg}
      color={color}
      mt={mt || "40px"}
      borderRadius={"12px"}
      border="1px solid"
      borderColor={bgLight ? bgLightBorderColor : borderColor}
      onClick={onClick}
      type={type}
      gap="10px"
      alignItems={"center"}
      isDisabled={disabled}
      isLoading={isLoading}
    >
      {text} {icon}
    </Button>
  );
};

export default PrimaryButton;
