import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../views/home";
import Login from "../views/login";
import ForgotPassword from "../views/forgotPassword";
import VerifyEmail from "../views/verifyEmail";
import ResetPassword from "../views/resetPassword";
import PasswordChanged from "../views/passwordChanged";
import TransactionHistory from "../views/transactionHiatory";
import Rates from "../views/rates";
import Analytics from "../views/analytics";
import Users from "../views/users";
import UserSummary from "../views/userSummary";
import UserWallet from "../views/userWallet";
import UserActivity from "../views/userActivity";
import UserReferral from "../views/userReferral";
import KycSettings from "../views/kycSettings";
import UserKyc from "../views/userKyc";
import Settings from "../views/settings";
import ReferralSettings from "../views/referralSettings";
// import PrivateRoute from "./privateRoute";

const routes = [
  {
    path: "/",
    Component: Home,
    isPrivate: true,
  },
  {
    path: "/transaction-history",
    Component: TransactionHistory,
    isPrivate: true,
  },
  {
    path: "/rates",
    Component: Rates,
    isPrivate: true,
  },
  {
    path: "/analytics",
    Component: Analytics,
    isPrivate: true,
  },
  {
    path: "/users",
    Component: Users,
    isPrivate: true,
  },
  {
    path: "/user-summary",
    Component: UserSummary,
    isPrivate: true,
  },
  {
    path: "/user-wallet",
    Component: UserWallet,
    isPrivate: true,
  },
  {
    path: "/user-activity",
    Component: UserActivity,
    isPrivate: true,
  },
  {
    path: "/user-referral",
    Component: UserReferral,
    isPrivate: true,
  },

  {
    path: "/kyc-settings",
    Component: KycSettings,
    isPrivate: true,
  },
  {
    path: "/user-kyc",
    Component: UserKyc,
    isPrivate: true,
  },
  {
    path: "/settings",
    Component: Settings,
    isPrivate: true,
  },
  {
    path: "/referral-settings",
    Component: ReferralSettings,
    isPrivate: true,
  },

  {
    path: "/login",
    Component: Login,
    isPrivate: false,
  },
  {
    path: "/forgot-password",
    Component: ForgotPassword,
    isPrivate: false,
  },
  {
    path: "/reset-password",
    Component: ResetPassword,
    isPrivate: false,
  },
  {
    path: "/verify-email",
    Component: VerifyEmail,
    isPrivate: false,
  },
  {
    path: "/password-changed",
    Component: PasswordChanged,
    isPrivate: false,
  },
];

export default function AppRoutes() {
  return (
    <Routes>
      {routes?.map(({ path, Component, isPrivate }) => (
        <Route
          key={path}
          element={
            // isPrivate ? (
            // <PrivateRoute component={<Component />} />
            // ) : (
            <Component />
            // )
          }
          path={path}
        />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
