import { Box, Flex, Image, Stack, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import LogoSmall from "../assets/logo-sm.svg";
import Logo from "../assets/logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import MinimizeIcon from "../assets/minimize-sm.svg";
import MinimizeText from "../assets/Minimize.svg";
import HomeInactive from "../assets/home-inactive-sm.svg";
import HomeActive from "../assets/home-active-sm.svg";
import HomeText from "../assets/Home.svg";
import TrxInactive from "../assets/transaction-inactive-sm.svg";
import TrxActive from "../assets/transaction-active-sm.svg";
import TrxText from "../assets/Transactions.svg";
import RatesInactive from "../assets/rates-inactive-sm.svg";
import RatesActive from "../assets/rates-active-sm.svg";
import RatesText from "../assets/Rates.svg";
import AnalyticsInactive from "../assets/analytics-inactive-sm.svg";
import AnalyticsActive from "../assets/analytics-active-sm.svg";
import AnalyticsText from "../assets/Analytics.svg";
import UsersInactive from "../assets/users-inactive-sm.svg";
import UsersActive from "../assets/users-active-sm.svg";
import UsersText from "../assets/Users.svg";
import SettingsInactive from "../assets/settings-inactive-sm.svg";
import SettingsActive from "../assets/settings-active-sm.svg";
import SettingsText from "../assets/Settings.svg";
import LogoutInactive from "../assets/logout-inactive.svg";
import LogoutText from "../assets/Logout.svg";

const SideBar = ({ isOpen, setIsOpen }: any) => {
  const navigate = useNavigate();

  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const bg = useColorModeValue("#FFF", "#000");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth <= 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setIsOpen]);

  useEffect(() => {
    if (screenWidth > 768 && isOpen === false) {
      setIsOpen(true); // Optional: Automatically open sidebar when width is greater than 768px if previously closed due to resizing below threshold
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenWidth, setIsOpen]);
  return (
    <Box
      bg={bg}
      h="100vh"
      overflowY={"scroll"}
      position={"fixed"}
      w={isOpen ? "198px" : "74px"}
      transition={"width 0.6s ease"}
      p={"65px 0px"}
      zIndex={2}
      sx={{
        // Scrollbar width for webkit browsers
        "::-webkit-scrollbar": {
          width: "0px",
        },
        // Scrollbar track style
        "::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        // Scrollbar thumb style
        "::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "0px",
        },
        // Scrollbar thumb on hover
        "::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }}
    >
      <Flex justify={"center"} align={"center"} gap={"15px"} px={"20px"}>
        <Image display={!isOpen ? "block" : "none"} src={LogoSmall} />
        <Image display={!isOpen ? "none" : "block"} src={Logo} />
      </Flex>

      <Stack
        gap={"60px"}
        mt={"65px"}
        px={isOpen ? "0px" : "20px"}
        pl={isOpen ? "42px" : "0px"}
      >
        <SideBarItem
          isOpen={isOpen}
          icon={MinimizeIcon}
          iconText={MinimizeText}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
        <SideBarItem
          isOpen={isOpen}
          icon={HomeInactive}
          iconActive={HomeActive}
          iconText={HomeText}
          onClick={() => {
            navigate("/");
          }}
          path={"/"}
        />
        <SideBarItem
          isOpen={isOpen}
          icon={TrxInactive}
          iconActive={TrxActive}
          iconText={TrxText}
          onClick={() => {
            navigate("/transaction-history");
          }}
          path={"/transaction-history"}
        />
        <SideBarItem
          isOpen={isOpen}
          icon={RatesInactive}
          iconActive={RatesActive}
          iconText={RatesText}
          onClick={() => {
            navigate("/rates");
          }}
          path={"/rates"}
        />
        <SideBarItem
          isOpen={isOpen}
          icon={AnalyticsInactive}
          iconActive={AnalyticsActive}
          iconText={AnalyticsText}
          onClick={() => {
            navigate("/analytics");
          }}
          path={"/analytics"}
        />
        <SideBarItem
          isOpen={isOpen}
          icon={UsersInactive}
          iconActive={UsersActive}
          iconText={UsersText}
          onClick={() => {
            navigate("/users");
          }}
          path={[
            "/users",
            "/user-summary",
            "/user-wallet",
            "user-referral",
            "/user-kyc",
            "/user-activity",
            "/kyc-settings",
          ]}
        />
        <SideBarItem
          isOpen={isOpen}
          icon={SettingsInactive}
          iconActive={SettingsActive}
          iconText={SettingsText}
          onClick={() => {
            navigate("/settings");
          }}
          path={["/settings", "/referral-settings"]}
        />
        <SideBarItem
          isOpen={isOpen}
          icon={LogoutInactive}
          iconText={LogoutText}
          onClick={() => {
            // navigate("/");
          }}
        />
      </Stack>
    </Box>
  );
};

export default SideBar;

const SideBarItem = ({
  isOpen,
  icon,
  onClick,
  iconText,
  path,
  iconActive,
}: any) => {
  const location = useLocation();

  const isActive = Array.isArray(path)
    ? path.includes(location.pathname)
    : location.pathname === path;

  return (
    <Flex
      justify={isOpen ? "" : "flex-end"}
      align={"center"}
      gap={"15px"}
      onClick={onClick}
      cursor={"pointer"}
    >
      <Image src={isActive ? iconActive : icon} alt="minimize" w={"30px"} />
      <Box display={isOpen ? "block" : "none"}>
        <Image src={iconText} alt="text" color={"gray"} />
      </Box>
    </Flex>
  );
};
