// src/theme.ts
import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  colors: {
    light: {
      primary: "#00ffe0",
      remainder: "#535353",
      text: {
        primary: "#000000",
        secondary: "#348537",
      },
      border: {
        primary: "#00CCB3",
        secondary: "#D9D9D9",
      },
      accent: {
        primary: "#00332D",
        secondary: "#EEE", // Secondary accent color
      },
      link: {
        primary: "#009986", // Primary accent color
        secondary: "#EEE", // Secondary accent color
      },
      badge: {
        bg: "#00665A",
        text: "#FFC960",
      },
      bg: "white",
      bgSecondary: "#F9F9F9",
      bgGray: "#E9E9E9",
      redColor: "#F00",
    },
    dark: {
      primary: "#00ffe0",
      text: {
        primary: "#FFFFFF",
        secondary: "#C7C7C7",
      },
      border: {
        primary: "#00FFE0",
        secondary: "#333",
      },
      accent: {
        primary: "#00FFE0",
        secondary: "#333",
      },
      link: {
        primary: "#00FFE0",
        secondary: "#333",
      },
      badge: {
        bg: "#FFC960",
        text: "#181818",
      },
      bg: "black",
      bgSecondary: "#1f1f1f",
    },
  },
  fonts: {
    body: "'Plus Jakarta Sans', sans-serif",
    heading: "'Plus Jakarta Sans', sans-serif",
  },
  styles: {
    global: {
      body: {
        bg: "#F3F3F3", // Background color of the app
        color: "#000", // Default text color
      },
    },
  },
  components: {
    Card: {
      baseStyle: {
        bg: "#FFF", // Default card background color
        _selected: {
          bg: "#00FFE0", // Background for selected card
        },
      },
    },
  },

  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});

export default customTheme;
